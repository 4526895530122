import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import inventoryFormModel from '../models/createInventoryModel';

const {
    formField: {
        
        inventoryCode,
        description,
    },
} = inventoryFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateInventoryForm: React.FC<OwnProps> = (props) => {
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();


    return (
        <Stack width="100%" spacing={5}>
            <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'} Inventory
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field
                    as={TextField}
                    fullWidth
                    {...inventoryCode}
                    error={touched[inventoryCode.name] && Boolean(errors[inventoryCode.name])}
                    helperText={touched[inventoryCode.name] && errors[inventoryCode.name] ? errors[inventoryCode.name] : ''}
                />
                

                <Field
                    as={TextField}
                    fullWidth
                    {...description}
                    error={touched[description.name] && Boolean(errors[description.name])}
                    helperText={touched[description.name] && errors[description.name] ? errors[description.name] : ''}
                />
            </Stack>


        </Stack>
    );
};

export default CreateInventoryForm;

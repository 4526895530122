import React, { useState } from 'react';
import { Button, Stack, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {useGetClientsQuery, usePostPropertyMutation} from "../../redux/vmsApi";

interface Development {
  id: string;
  name: string;
}

interface Phase {
  id: string;
  name: string;
}

interface ClientWithRelations {
  id?: string; // id can be optional
  clientName: string;
}

interface AddPropertyFormValues {
  development: string;
  phase: string;
  unitNumber: string;
  clientId: string;
}

const initialValues: AddPropertyFormValues = {
  development: '',
  phase: '',
  unitNumber: '',
  clientId: '',
};

// Hardcoded developments and phases
const developments: Development[] = [
  { id: 'pokugara', name: 'Pokugara Residential Estate' },
  { id: 'millennium', name: 'Millennium Heights' },
  { id: 'pomona', name: 'Pomona City' },
];

const phasesByDevelopment: Record<string, Phase[]> = {
  'pokugara': [
    { id: 'townhouse', name: 'Phase One Townhouse' },
    { id: 'villa', name: 'Villa' },
    { id: 'manor', name: 'Manor' },
    { id: 'garden', name: 'Garden' },
    { id: 'stand', name: 'Stand' },
  ],
  'millennium': [
    { id: 'block1', name: 'Block One' },
    { id: 'block2', name: 'Block Two' },
    { id: 'block3', name: 'Block Three' },
    { id: 'block4', name: 'Block Four' },
  ],
  'pomona': [
    { id: 'phase1A', name: 'Phase 1A' },
    { id: 'phase1BC', name: 'Phase 1B&C' },
  ],
};

const validationSchema = Yup.object({
  development: Yup.string().required("Development is required"),
  phase: Yup.string().required("Phase is required"),
  unitNumber: Yup.string().required("Unit Number is required"),
  clientId: Yup.string().required("Client is required"),
});

const AddPropertyForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedDevelopment, setSelectedDevelopment] = useState<string>('');
  const { data: clients = [], isLoading } = useGetClientsQuery({});
  const [postProperty] = usePostPropertyMutation();

  const handleSubmit = async (values: AddPropertyFormValues, { resetForm }: { resetForm: () => void }) => {
    try {
      const response = await postProperty({propertyData: values}).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('There was an error submitting the form', error);
    }
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <Stack spacing={4} width="100%" p={5}>
            {!isSubmitted ? (
              <>
                <Typography variant='h2' gutterBottom>
                  Add Property
                </Typography>

                <FormControl fullWidth required>
                  <InputLabel>Development</InputLabel>
                  <Field
                    name="development"
                    as={Select}
                    label="Development"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const value = e.target.value as string;
                      setSelectedDevelopment(value);
                      setFieldValue('development', value);
                      setFieldValue('phase', ''); // Reset phase when development changes
                    }}
                  >
                    {developments.map((dev) => (
                      <MenuItem key={dev.id} value={dev.id}>{dev.name}</MenuItem>
                    ))}
                  </Field>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel>Phase</InputLabel>
                  <Field
                    name="phase"
                    as={Select}
                    label="Phase"
                    disabled={!selectedDevelopment}
                  >
                    {selectedDevelopment && phasesByDevelopment[selectedDevelopment]
                      ? phasesByDevelopment[selectedDevelopment].map((phase: Phase) => (
                        <MenuItem key={phase.id} value={phase.id}>{phase.name}</MenuItem>
                      ))
                      : <MenuItem value="">No phases available</MenuItem>}
                  </Field>
                </FormControl>

                <Field
                  name="unitNumber"
                  as={TextField}
                  label="Unit Number"
                  variant="outlined"
                  fullWidth
                  required
                />

                <Autocomplete
                  options={clients || []}
                  getOptionLabel={(option: ClientWithRelations) => option.clientName}
                  onChange={(event: React.SyntheticEvent, value: ClientWithRelations | null) => {
                    setFieldValue('clientId', value ? value.id || '' : '');
                  }}
                  renderInput={(params) => <TextField {...params} label="Client" variant="outlined" fullWidth required />}
                />

                <Button variant="contained" type="submit" sx={{ mt: 4 }}>
                  Add Property
                </Button>
              </>
            ) : (
              <>
                <Typography variant='h5' gutterBottom style={{ color: '#123456' }}>
                  Property Added Successfully!
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      disabled
                    />
                  }
                  label="Thank you for adding a new property."
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsSubmitted(false)}
                >
                  Add Another
                </Button>
              </>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AddPropertyForm;

import React, { useState } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Switch,
  Typography,
  Card,
  CardContent,
  Stack,
} from "@mui/material";

const ProcurementCalculator = () => {
  const [inputType, setInputType] = useState("VATInclusive");
  const [outputType, setOutputType] = useState("VATExclusive");
  const [unitPrice, setUnitPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [isDiscountPercentage, setIsDiscountPercentage] = useState(false);
  const [includeVAT, setIncludeVAT] = useState(false);
  const [result, setResult] = useState<number | null>(null);

  const calculateResult = () => {
    let VATExclusive = 0;
    let VATInclusive = 0;
  
    
    const discountValue = isDiscountPercentage
      ? (discount / 100) * unitPrice
      : discount;
  
    if (inputType === "VATInclusive") {
    
      VATInclusive = unitPrice;
  
      
      const base = VATInclusive / (1 + 0.15);
      VATExclusive = base + discountValue / (1 + 0.15);
    } else {
     
      VATExclusive = unitPrice;
  
    
      VATInclusive =
        (VATExclusive - discountValue) +
        0.15 * (VATExclusive - discountValue);
    }
  

    if (outputType === "VATExclusive") {
      setResult(VATExclusive);
    } else if (outputType === "VATInclusive") {
      setResult(VATInclusive);
    } else if (outputType === "Discount") {
      setResult(discountValue);
    }
  };
  

  const clearForm = () => {
    setInputType("VATInclusive");
    setOutputType("VATExclusive");
    setUnitPrice(0);
    setDiscount(0);
    setIsDiscountPercentage(false);
    setIncludeVAT(false);
    setResult(null);
  };

  return (
    <Card sx={{ width: "100%", padding: 3, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 2 }}>
          Procurement Calculator
        </Typography>

        <Grid container spacing={2}>
        
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Input Type</InputLabel>
              <Select
                value={inputType}
                onChange={(e) => setInputType(e.target.value)}
              >
                <MenuItem value="VATInclusive">Unit Price (VAT Inclusive)</MenuItem>
                <MenuItem value="VATExclusive">Unit Price (VAT Exclusive)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

         
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Output Type</InputLabel>
              <Select
                value={outputType}
                onChange={(e) => setOutputType(e.target.value)}
              >
                <MenuItem value="VATInclusive">Unit Price (VAT Inclusive)</MenuItem>
                <MenuItem value="VATExclusive">Unit Price (VAT Exclusive)</MenuItem>
                <MenuItem value="Discount">Discount</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          
          <Grid item xs={12} sm={6}>
            <TextField
              label="Unit Price"
              type="number"
              value={unitPrice}
              onChange={(e) => setUnitPrice(parseFloat(e.target.value) || 0)}
              fullWidth
            />
          </Grid>

          
          <Grid item xs={12} sm={6}>
            <TextField
              label={isDiscountPercentage ? "Discount (%)" : "Discount (Value)"}
              type="number"
              value={discount}
              onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
              fullWidth
            />
          </Grid>

          
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Discount as Percentage</Typography>
              <Switch
                checked={isDiscountPercentage}
                onChange={(e) => setIsDiscountPercentage(e.target.checked)}
              />
            </Stack>
          </Grid>

         
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>Include VAT (15%)</Typography>
              <Switch
                checked={includeVAT}
                onChange={(e) => setIncludeVAT(e.target.checked)}
              />
            </Stack>
          </Grid>

          
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={calculateResult}
            >
              Calculate
            </Button>
          </Grid>

          
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={clearForm}
            >
              Clear
            </Button>
          </Grid>

          
          {result !== null && (
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
                Result: {result.toPrecision(10)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProcurementCalculator;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Stack } from "@mui/material";
import { Form, Formik } from 'formik';
import {
  Requisition,
  RequisitionData,
  RequisitionItem,
  useGetDevelopmentsByIdQuery,
  useGetProcurementProcessesByIdQuery,
  useGetUsersMeQuery,
  usePostCreateProcurementProcessMutation,
  usePutProcurementProcessesByIdMutation,
} from '../../../redux/vmsApi';
import LoadingCover from '../../common/LoadingCover';
import PageWithHeader from '../../common/PageWithHeader';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';

import createRequisitionModel, { createRequisitionModelType } from "../../forms/models/createRequisitionModel";
import CreateRequisitionForm from "../../forms/CreateRequisitionForm";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ProcurementProcessDashboard from '../dashboards/ProcurementProcessDashboard';
import RenderRequisitionDetails from '../common/RenderRequisitionDetails';
import RenderRequisitionHeader from '../common/renderRequisitionHeader';


type MyFormValues = createRequisitionModelType;

const createRequisitionModelInitial = createRequisitionModel.initialValues;

interface RouteParams {
  uuid: string;
}

const CreateRequisitionPage: React.FC<{ onSuccess?: (requisition: Requisition) => void; }> = ({ onSuccess }) => {
  const [isClicked, setIsClicked] = useState(false);
  const isInProcess:boolean=true;
  const { uuid } = useParams<RouteParams>();
  const [updateProcurementProcess, { isLoading: isSubmitting }] = usePutProcurementProcessesByIdMutation();
  const { data: user } = useGetUsersMeQuery();
  const [createProcurementProcessForm, { isLoading: isSecondFormLoading }] = usePostCreateProcurementProcessMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [requisitionData, setRequisitionData] = useState<RequisitionData | null>(null);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);


  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: uuid }, { skip: !uuid });
  const developmentId = procurementProcess?.requisition?.development.id;
  const { data: development } = useGetDevelopmentsByIdQuery(
    { id: developmentId! },
    { skip: !developmentId }
  );
  useEffect(() => {
    setShowOverlay(isSubmitting || isSecondFormLoading);
    if (showSuccessMsg) {
      setTimeout(() => {
        setShowOverlay(false);

        setRedirectToDashboard(true);
      }, 3000);
    }
  }, [isSubmitting, isSecondFormLoading, showSuccessMsg]);

  const initialValues: MyFormValues = {
    ...createRequisitionModelInitial,
  };

  // const generateRequisitionNumber = (date = new Date()) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const seconds = String(date.getSeconds()).padStart(2, '0');


  //   return `REQ-${year}${month}${day}-${hours}${minutes}${seconds}`;
  // };

  const handleSubmit = async (values: MyFormValues) => {
    setIsClicked(true);
    console.log("+++++values++++++", values);
    const requisitionItems: RequisitionItem[] = values.items.map(item => ({
      description: item.description,
      itemCode: item.itemCode,
      quantity: {
        quantityValue: typeof item.quantity.quantityValue === 'string'
          ? parseInt(item.quantity.quantityValue, 10) || 0
          : Math.floor(item.quantity.quantityValue),
        quantityUnit: item.quantity.quantityUnit,
      },
      unit: {
        id: item.unit,
        name: item.unitName
      },
      rate: typeof item.rate === 'string'
        ? parseInt(item.rate, 10) || 0
        : Math.floor(item.rate),
      procured: false,
      totalCostPerItem: typeof item.totalCostPerItem === 'string'
        ?parseInt(item.totalCostPerItem,10)||0
        : Math.floor(item.totalCostPerItem)
    }));

    console.log("requisitionItems", requisitionItems);

    const reqData: RequisitionData = {
      title: values.title,
      development: {
        name: values.developmentName,
        id: values.development
      },
      project: {
        name: values.projectName,
        id: values.project
      },
      items: requisitionItems,
      totalCost: values.totalCost,
      approved: false,
      reqNumber: ``,
      documents:values.documents
    };
    setRequisitionData(reqData);

    setShowOverlay(true);
    
    try {
      let result;
      let procurementProcessPartial: any;
      let newProcurementProcess: any;
      let formData = new FormData();
      let currentDate = new Date();
      if (procurementProcess?.requisition) {
        procurementProcessPartial = {
          currentTask: "SiteManagerReviewPurchaseRequisition",
          previousTask: "RaisePurchaseRequisition",
          currentTaskOwner: "site-manager",
          initiatedDate: procurementProcess?.initiatedDate,
          lastActionedDate: currentDate,
          status: "New Requisition",
          siteManagerReview: {
            comment: procurementProcess?.siteManagerReview?.comment,
            approved: procurementProcess?.siteManagerReview?.approved,
            dateReviewed: procurementProcess?.siteManagerReview?.dateReviewed,
            user: {
              ...procurementProcess?.siteManagerReview?.user,
              previousToken: '',
            },
          },
          initiatedByUser: {
            ...procurementProcess?.initiatedByUser,
            previousToken: '',
          },
          requisition: {
            ...procurementProcess?.requisition,
            id: '',
            project: procurementProcess?.requisition.project,
            development: procurementProcess?.requisition.development,
            documents:procurementProcess?.requisition.documents,
            reqNumber: procurementProcess?.requisition.reqNumber,
            totalCost: values.totalCost,
            items: reqData.items?.map((item:any) => ({
              ...item,
              quantity: {
                ...item.quantity,
                id: undefined,
              },
              unit: {
                id: item.unit.id,
                name: item.unit.name
              },
              id: undefined,
            })),
          },
          requisitionDocument:procurementProcess?.requisitionDocument,
          purchaseOrderDocument:procurementProcess?.purchaseOrderDocument,
        };
        console.log("+++++++++procurementProcessPartial++++++++", procurementProcessPartial);
         result = await updateProcurementProcess({ id: uuid, procurementProcessPartial }).unwrap();
      } else {
        // reqData["reqNumber"] = generateRequisitionNumber(currentDate);
        newProcurementProcess = {
          currentTask: "SiteManagerReviewPurchaseRequisition",
          previousTask: "RaisePurchaseRequisition",
          currentTaskOwner: "site-manager",
          initiatedDate: currentDate,
          lastActionedDate: currentDate,
          initiatedByUser: user,
          requisition: reqData,
          siteManagerReview: undefined,
          status: "New Requisition",
          requisitionDocument:{name:"",url:""},
          purchaseOrderDocument:{name:"",url:""},
        };

        console.log("newProcurementProcess", newProcurementProcess);
         result = await createProcurementProcessForm({ newProcurementProcess }).unwrap();
      }

      if (result) {
        console.log('ProcurementProcessForm submitted successfully');
        setShowOverlay(false);
        setShowSuccessMsg(true);
      }
    } catch (error) {
      setShowOverlay(false);
      console.error("Error submitting ProcurementProcessForm:", error);
    }
  };

  if (redirectToDashboard) {
    return <ProcurementProcessDashboard />;
  }

  return (
    <PageWithHeader>
      {showOverlay && <LoadingCover isSuccess={showSuccessMsg} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              {procurementProcess && (
                <>
                  <RenderRequisitionHeader development={development || 'Development'} />
                  <RenderRequisitionDetails
                    development={development}
                    procurementProcess={procurementProcess}
                    selectedSupplier={null}
                    openDialog={null}
                    handleDialogOpen={() => { }}
                    handleDialogClose={() => { }}
                    handleSelectSupplier={() => { }}
                    showQuotationForm={false}
                    setShowQuotationForm={() => { }}
                    isInProcess={isInProcess}
                  />
                </>
              )}

              <CreateRequisitionForm />
              <Button
                variant="contained"
                type="submit"
                disabled={isClicked}
                
              >
                {procurementProcess?.requisition ? 'Resubmit Corrected Requisition' : 'Raise Requisition'}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setRedirectToDashboard(true)}
              >
                Go to Dashboard
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </PageWithHeader>
  );
};

export default CreateRequisitionPage;
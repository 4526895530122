const requisitionFormModel = {
    formId: 'requisitionForm',
    formField: {
        title: {
            name: 'title',
            label: 'Title',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        development: {
            name: 'Development',
            label: 'Development',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        developmentName: {
            name: 'DevelopmentName',
            label: 'DevelopmentName',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        project: {
            name: 'Project',
            label: 'Project',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        projectName: {
            name: 'project Name',
            label: 'project Name',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        totalCost: {
            name: 'totalCost',
            label: 'Total Cost',

        },
        documents: {
            name: 'attachments',
            label: 'Attachments',
            required: false,
        },

        items: {
            name: 'items',
            label: 'Requisition Items',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                unit: {
                    name: 'unit',
                    label: 'Housing Unit',
                },
                unitName: {
                    name: 'unit',
                    label: 'Housing Unit',
                    required: true,
                    requiredErrorMsg: 'Housing is required',
                },
                itemCode: {
                    name: 'itemCode',
                    label: 'Item Code',
                    required: true,
                    requiredErrorMsg: 'Unit is required',
                },
                description: {
                    name: 'description',
                    label: 'Description',
                    required: true,
                    requiredErrorMsg: 'Description is required',
                },
                quantity: {
                    name: 'quantity',
                    label: 'Quantity',
                    required: true,
                    requiredErrorMsg: 'Quantity is required',
                    quantityValue: {
                        name: 'quantityValue',
                        label: 'Quantity',
                        required: true,
                        requiredErrorMsg: 'Quantity is required',
                    },
                    quantityUnit: {
                        name: 'quantityUnit',
                        label: 'Unit',
                        required: true,
                        requiredErrorMsg: 'Unit is required',
                    }
                },
                rate: {
                    name: 'rate',
                    label: 'Unit Price',
                    required: true,
                    requiredErrorMsg: 'rate is required',
                },
                totalCostPerItem: {
                    name: 'totalAmount',
                    label: 'Total Amount',
                    required: true,
                    requiredErrorMsg: 'Total Amount is required',
                },

               
            },


        },
    },
    initialValues: {
        title: '',
        development: '',
        project: '',
        developmentName: '',
        projectName: '',
        totalCost: 0,
        documents: [],
        items: [{
            unit: '',
            unitName: '',
            bomItem: '',
            description: '',
            itemCode:'',
            quantity: {
                quantityValue: 0,
                quantityUnit: ''
            },
            rate: 0,
            totalCostPerItem: 0
        }],
    },
};

export type QuantityType = {
    quantityValue: number;
    quantityUnit: string;

};

export type ItemType = {
    quantity: QuantityType;
    description: string;
    itemCode:string;
    unit: string;
    unitName: string;
    rate: number;
    totalCostPerItem: number;
};



export type createRequisitionModelType = {
    items: ItemType[];
    development: string;
    project: string;
    title: string;
    developmentName: string;
    projectName: string;
    totalCost: number;
    documents: { name: string; url: string; }[]
};

export default requisitionFormModel;

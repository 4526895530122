import React from 'react';
import AddClientForm from "./SiteVisitApplicationForm";
import {Paper, Typography} from "@mui/material";
import PageWithHeader from "../common/PageWithHeader";
import {useGetClientsQuery} from "../../redux/vmsApi";
import MySiteVisitApplicationList from './MySiteVisitApplicationList';

const SiteVisitApplicationPage: React.FC = () => {
  const {data: clients, isLoading} = useGetClientsQuery({});


  return (
    <PageWithHeader padding={5}>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
       Site Visit Application
      </Typography>
      <Paper sx={{my: 5}}>
        <AddClientForm />
      </Paper>
      <Paper sx={{my: 5}}>
        <MySiteVisitApplicationList />
      </Paper>
     
    </PageWithHeader>
  )
}

export default SiteVisitApplicationPage;
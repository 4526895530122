import * as Yup from 'yup';
import createAgreementModel from "./createAgreementModel";

const {
  formField: {
    clientName,
    clientIdNumber,
    clientAddress,
    company,
    representedBy,
    purchasePrice,
    unitNumber,
    block,
    paymentsToDate,
    firstInstallmentPaymentDate,
    lastInstallmentPaymentDate,
    numberOfInterestFreeMonths,
    propertyDescription,
    unitArea,
    chargeInterest,
    contractReference,
  }
} = createAgreementModel;

const createAgreementSchema = Yup.object().shape({
  [clientName.name]: Yup.string()
    .required(clientName.requiredErrorMsg),
  [clientIdNumber.name]: Yup.string()
    .required(clientIdNumber.requiredErrorMsg),
  [clientAddress.name]: Yup.string()
    .required(clientAddress.requiredErrorMsg),
  [company.name]: Yup.string()
    .required(company.requiredErrorMsg),
  [representedBy.name]: Yup.string()
    .required(representedBy.requiredErrorMsg),
  [purchasePrice.name]: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(purchasePrice.requiredErrorMsg),
  [unitNumber.name]: Yup.string()
    .required(unitNumber.requiredErrorMsg),
  [block.name]: Yup.string()
    .nullable(),
  [paymentsToDate.name]: Yup.array()
    .of(
      Yup.object().shape({
        receiptNumber: Yup.string().required("Receipt Number is required."),
        date: Yup.date().required("Payment date is required."),
        amount: Yup.number().required("Payment amount is required.")
      })
    ),
  [firstInstallmentPaymentDate.name]: Yup.date()
    .nullable(),
  [lastInstallmentPaymentDate.name]: Yup.date()
    .nullable(),
  [numberOfInterestFreeMonths.name]: Yup.number()
    .required(numberOfInterestFreeMonths.requiredErrorMsg),
  [propertyDescription.name]: Yup.string()
    .required(propertyDescription.requiredErrorMsg),
  [unitArea.name]: Yup.number()
    .required(unitArea.requiredErrorMsg)
    .positive(unitArea.invalidErrorMsg),
  [chargeInterest.name]: Yup.boolean(),
  [contractReference.name]: Yup.string()
    .required(contractReference.requiredErrorMsg)
});


export default createAgreementSchema
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info'; 
interface ConfirmationDialogProps {
  actionType: 'Approve' | 'Reject' | 'Submit Info';
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ actionType, onConfirm }) => {
  const handleAction = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div style={customDialogStyles.container as React.CSSProperties}>
          <h1 style={customDialogStyles.title as React.CSSProperties}>
            {actionType === 'Approve'
              ? '✅ Confirm Approval'
              : actionType === 'Reject'
              ? '⚠️ Confirm Rejection'
              : 'ℹ️ Submit Additional Info'} 
          </h1>
          <p style={customDialogStyles.message as React.CSSProperties}>
            Are you sure you want to <strong>{actionType}</strong>? This action cannot be undone.
          </p>
          <div style={customDialogStyles.buttonsContainer as React.CSSProperties}>
            <Button
              variant="contained"
              color={
                actionType === 'Approve'
                  ? 'success'
                  : actionType === 'Reject'
                  ? 'error'
                  : 'primary' 
              }
              startIcon={
                actionType === 'Approve'
                  ? <CheckCircleIcon />
                  : actionType === 'Reject'
                  ? <CancelIcon />
                  : <InfoIcon /> 
              }
              onClick={() => {
                onConfirm();
                onClose();
              }}
              style={customDialogStyles.actionButton}
            >
              Yes, {actionType}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
    
                onClose();
              }}
              style={customDialogStyles.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <Button
      onClick={handleAction}
      style={{
        ...buttonStyle,
        backgroundColor:
          actionType === 'Approve'
            ? '#4CAF50'
            : actionType === 'Reject'
            ? '#F44336'
            : '#2196F3', 
        border:
          `2px solid ${actionType === 'Approve'
            ? '#388E3C'
            : actionType === 'Reject'
            ? '#D32F2F'
            : '#1976D2'}`, 
      }}
    >
      {actionType === 'Approve' ? '✅ Approve' : actionType === 'Reject' ? '❌ Reject' : 'ℹ️ Submit Info'} 
    </Button>
  );
};

const customDialogStyles = {
  container: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
    padding: '30px',
    maxWidth: '400px',
    margin: 'auto',
    textAlign: 'center' as 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#333',
  },
  message: {
    fontSize: '16px',
    marginBottom: '20px',
    color: '#666',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '10px 20px',
    borderRadius: '30px',
  },
  cancelButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '10px 20px',
    borderRadius: '30px',
    backgroundColor: '#f5f5f5',
    borderColor: '#ccc',
    color: '#333',
  },
};

const buttonStyle = {
  color: '#fff',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '30px',
  margin: '10px',
  transition: 'background-color 0.3s, box-shadow 0.3s',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
};

export default ConfirmationDialog;

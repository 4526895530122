import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { Development, Project } from '../../redux/vmsApi';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CreateDevelopmentForm from './forms/CreateDevelopmentForm';

type EditDevelopmentDialogProps = {
  open: boolean;
  onClose: () => void;
  development: Development;
  onSave: (values: any) => void;
};

const EditDevelopmentDialog: React.FC<EditDevelopmentDialogProps> = ({ open, onClose, development, onSave }) => {
  const initialValues = {
    name: development.name,
    description: development.description,
    projects: development.projects.map((project: Project) => ({
      projectName: project.name,
      projectDescription: project.description,
      projectStatus: project.status,
      projectStartDate: project.startDate
    }))
  };

  const handleSubmit = async (values: any) => {
    const projects: Project[] = values.projects.map((project: any) => ({
      name: project.projectName,
      description: project.projectDescription,
      status: project.projectStatus,
      startDate: project.projectStartDate?.toString(),
    }));

    const developmentData: Development = {
      id: development.id,
      name: values.name,
      description: values.description,
      projects: projects,
    };

    onSave(developmentData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <Stack spacing={4} width="100%" p={2}>
                <CreateDevelopmentForm editing />
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Stack>
            </Form>
          </Formik>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditDevelopmentDialog;

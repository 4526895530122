import React, {ChangeEvent, useState} from 'react';
import {
  Button,
  Checkbox, FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem, Select,
  SelectChangeEvent,
  Stack,
  TextField, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import {Field, FieldArray, FieldProps, useFormikContext} from "formik";
import createAgreementModel from "./createAgreementModel";
import {DatePicker} from "@mui/x-date-pickers";
import {Delete as DeleteIcon} from '@mui/icons-material';

const {
  formField:
    {
      clientName,
      clientIdNumber,
      clientAddress,
      company,
      representedBy,
      purchasePrice,
      unitNumber,
      block,
      phase,
      paymentsToDate,
      firstInstallmentPaymentDate,
      lastInstallmentPaymentDate,
      numberOfInterestFreeMonths,
      propertyDescription,
      unitArea,
      chargeInterest,
      contractReference,
      clientDob,
      landShare,
      clientCompanyName,
    }
} = createAgreementModel;

const CreateAgreementForm = () => {
  const {errors, touched, values, setFieldValue} = useFormikContext<any>();

  const handleDatePickerChange = (fieldName: string, value: unknown) => {
      setFieldValue(fieldName, value);
  };


  const developmentValues = [
    {
      label: "Millenium Heights",
      value: "seatrite"
    }, 
    {
      label: "Pokugara Residential Estate",
      value: "pokugara"
    },
    {
      label: "Pomona City",
      value: "pomona"
    },
  ];
  
  const representedByOptions = [
    {
      label: "Tatiana Aleshina",
      value: "tatiana"
    }, 
    {
      label: "Simbarashe Kadye",
      value: "simba"
    }
  ];
  const mhBlockOptions = [{value: "Block 1"}, {value: "Block 2"}, {value: "Block 3"}, {value: "Block 4"}];
  const pomonaPhases = [{value: "1A"}, {value: "1B&C"}];
  const apartmentDescriptionOptions = [{value: "Studio"}, {value: "1 Bedroom"}, {value: "2 Bedroom"}, {value: "3 Bedroom"}];
  const townhouseDescriptionOptions = [{value: "Garden"}, {value: "Manor"}, {value: "Villa"}];

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target;
    setFieldValue(name, checked);
  };

  const [entityType, setEntityType] = useState('Individual');
  const [agreementType, setAgreementType] = useState('ica');

  return (
    <Stack width="100%" spacing={4} pt={4}>
      <FormControl variant="outlined">
        <InputLabel id="agreement-select-label">Agreement Type</InputLabel>
        <Select
          label={'Agreement Type'}
          value={agreementType}
          autoComplete="off"
          onChange={(event: SelectChangeEvent) => {
            setAgreementType(event.target.value);
          }}
        >
            <MenuItem key={'aos'} value={'aos'}>
              Agreement of Sale
            </MenuItem>
            <MenuItem key={'ica'} value={'ica'}>
              Investment and Construction Agreement
            </MenuItem>
        </Select>
      </FormControl>
      <Stack direction={'row'} spacing={4}>
        {/* Contract Reference */}
        <Field
          as={TextField}
          fullWidth
          {...contractReference}
          type="text"
          error={touched[contractReference.name] && Boolean(errors[contractReference.name])}
          helperText={touched[contractReference.name] && errors[contractReference.name] ? errors[contractReference.name] : ''}
        />
      </Stack>
      <Stack direction={'row'} spacing={4}>
        <ToggleButtonGroup
          color="primary"
          value={entityType}
          exclusive
          onChange={(event, newEntityType) => {
            if (newEntityType !== null) { // Prevent deselection
              setEntityType(newEntityType);
            }
          }}
          aria-label="Entity Type"
        >
          <ToggleButton value="Individual">Individual</ToggleButton>
          <ToggleButton value="Company">Company</ToggleButton>
        </ToggleButtonGroup>

        {/* Conditionally render Company Name and Company ID if entityType is 'Company' */}
        {entityType === 'Company' && (
          <React.Fragment>
            <Field
              as={TextField}
              fullWidth
              {...clientCompanyName} // Use the correct field definition from your model
              label="Company Name"
              type="text"
              error={touched[clientCompanyName.name] && Boolean(errors[clientCompanyName.name])}
              helperText={touched[clientCompanyName.name] && errors[clientCompanyName.name] ? errors[clientCompanyName.name] : ''}
            />
          </React.Fragment>
        )}
        {/* Client Name */}
        <Field
          as={TextField}
          fullWidth
          {...clientName}
          type="text"
          error={touched[clientName.name] && Boolean(errors[clientName.name])}
          helperText={touched[clientName.name] && errors[clientName.name] ? errors[clientName.name] : ''}
        />

        {/* Client ID Number */}
        <Field
          as={TextField}
          fullWidth
          {...clientIdNumber}
          type="text"
          error={touched[clientIdNumber.name] && Boolean(errors[clientIdNumber.name])}
          helperText={touched[clientIdNumber.name] && errors[clientIdNumber.name] ? errors[clientIdNumber.name] : ''}
        />
        {/* Client DOB */}
        {agreementType === 'aos' &&
          <Field
            as={TextField}
            fullWidth
            {...clientDob}
            type="text"
            error={touched[clientDob.name] && Boolean(errors[clientDob.name])}
            helperText={touched[clientDob.name] && errors[clientDob.name] ? errors[clientDob.name] : ''}
          />
        }
      </Stack>

      {/* Client Address */}
      <Field
        as={TextField}
        fullWidth
        {...clientAddress}
        type="text"
        error={touched[clientAddress.name] && Boolean(errors[clientAddress.name])}
        helperText={touched[clientAddress.name] && errors[clientAddress.name] ? errors[clientAddress.name] : ''}
      />

      <Stack direction={'row'} spacing={4}>
        {/* Company - Dropdown */}
        <Field
          as={TextField}
          fullWidth
          select
          {...company}
          error={touched[company.name] && Boolean(errors[company.name])}
          helperText={touched[company.name] && errors[company.name] ? errors[company.name] : ''}
        >
          {developmentValues.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>

        {/* Represented By - Dropdown */}
        <Field
          as={TextField}
          fullWidth
          select
          {...representedBy}
          error={touched[representedBy.name] && Boolean(errors[representedBy.name])}
          helperText={touched[representedBy.name] && errors[representedBy.name] ? errors[representedBy.name] : ''}
        >
          {representedByOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </Stack>

      <Stack direction={'row'} spacing={4}>
        {/* Purchase Price */}
        <Field
          as={TextField}
          fullWidth
          {...purchasePrice}
          type="number"
          error={touched[purchasePrice.name] && Boolean(errors[purchasePrice.name])}
          helperText={touched[purchasePrice.name] && errors[purchasePrice.name] ? errors[purchasePrice.name] : ''}
        />

        {/* Block - Dropdown */}
        {values[company.name] === 'seatrite' &&
          <Field
            as={TextField}
            fullWidth
            select
            {...block}
            error={touched[block.name] && Boolean(errors[block.name])}
            helperText={touched[block.name] && errors[block.name] ? errors[block.name] : ''}
          >
            {mhBlockOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Field>
        }

        {values[company.name] === 'pomona' &&
          <Field
            as={TextField}
            fullWidth
            select
            {...phase}
            error={touched[phase.name] && Boolean(errors[phase.name])}
            helperText={touched[phase.name] && errors[phase.name] ? errors[phase.name] : ''}
          >
            {pomonaPhases.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Field>
        }

        {/* Unit Number */}
        <Field
          as={TextField}
          fullWidth
          {...unitNumber}
          type="text"
          error={touched[unitNumber.name] && Boolean(errors[unitNumber.name])}
          helperText={touched[unitNumber.name] && errors[unitNumber.name] ? errors[unitNumber.name] : ''}
        />
        {/* Land Share Number */}
        {values.company === 'pokugara' &&
          <Field
            as={TextField}
            fullWidth
            {...landShare}
            type="text"
            error={touched[landShare.name] && Boolean(errors[landShare.name])}
            helperText={touched[landShare.name] && errors[landShare.name] ? errors[landShare.name] : ''}
          />
        }
      </Stack>

      <Stack direction={'row'} spacing={4}>
        {/* Property Description - Dropdown */}
        {values[company.name] !== 'pomona' &&
          <Field
            as={TextField}
            fullWidth
            select
            {...propertyDescription}
            error={touched[propertyDescription.name] && Boolean(errors[propertyDescription.name])}
            helperText={touched[propertyDescription.name] && errors[propertyDescription.name] ? errors[propertyDescription.name] : ''}
          >
            {(values[company.name] === 'seatrite' ? apartmentDescriptionOptions :
              values[company.name] === 'pokugara' ? townhouseDescriptionOptions : [])
              .map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))
            }
          </Field>
        }

        {/* Unit Area */}
        <Field
          as={TextField}
          fullWidth
          {...unitArea}
          type="number"
          error={touched[unitArea.name] && Boolean(errors[unitArea.name])}
          helperText={touched[unitArea.name] && errors[unitArea.name] ? errors[unitArea.name] : ''}
        />
      </Stack>

      <Stack direction={'row'} spacing={4}>
        {/* First Installment Payment Date */}
        <Field
          {...firstInstallmentPaymentDate}
          render={({field}: FieldProps) => (
            <DatePicker
              {...firstInstallmentPaymentDate}
              {...field}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              onChange={(value) => handleDatePickerChange(firstInstallmentPaymentDate.name, value)}
            />
          )}
        />

        {/* Last Installment Payment Date */}
        <Field
          {...lastInstallmentPaymentDate}
          render={({field}: FieldProps) => (
            <DatePicker
              {...lastInstallmentPaymentDate}
              {...field}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              onChange={(value) => handleDatePickerChange(lastInstallmentPaymentDate.name, value)}
            />
          )}
        />
      </Stack>

      <FormControlLabel
        control={<Checkbox
          checked={values[chargeInterest.name]}
          onChange={handleCheckboxChange}
        />
        }
        {...chargeInterest}
      />

      {/* Number of Interest Free Months */}
      <Field
        as={TextField}
        fullWidth
        name={numberOfInterestFreeMonths.name}
        label={numberOfInterestFreeMonths.label}
        type="number"
        error={touched[numberOfInterestFreeMonths.name] && Boolean(errors[numberOfInterestFreeMonths.name])}
        helperText={touched[numberOfInterestFreeMonths.name] && errors[numberOfInterestFreeMonths.name] ? errors[numberOfInterestFreeMonths.name] : ''}
      />

      <FieldArray
        name={paymentsToDate.name}
        render={arrayHelpers => (
          <div>
            {values[paymentsToDate.name].map((payment: any, index: any) => (
              <Stack key={index} direction="row" spacing={4} alignItems="center" mt={4}>
                <Field
                  name={`${paymentsToDate.name}.${index}.date`}
                  render={({field}: FieldProps) => (
                    <DatePicker
                      {...field}
                      label="Payment Date"
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(value) => setFieldValue(`${paymentsToDate.name}.${index}.date`, value)}
                    />
                  )}
                />
                <Field
                  as={TextField}
                  name={`${paymentsToDate.name}.${index}.receiptNumber`}
                  label="Receipt Number"
                  type="text"
                />
                <Field
                  as={TextField}
                  name={`${paymentsToDate.name}.${index}.amount`}
                  label="Payment Amount"
                  type="number"
                />
                <IconButton onClick={() => arrayHelpers.remove(index)}>
                  <DeleteIcon/>
                </IconButton>
              </Stack>
            ))}
            <Button
              type="button"
              onClick={() => arrayHelpers.push({date: null, amount: ''})}
            >
              Add Payment
            </Button>
          </div>
        )}
      />

    </Stack>
  )
}

export default CreateAgreementForm;
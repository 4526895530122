import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import { SupervisorPartial } from '../../redux/vmsApi';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { SuperviseeType } from '../forms/models/supervisorModel';
import CreateSupervisorForm from './CreateSupervisorForm';

type EditSupervisorDialogProps = {
  open: boolean;
  onClose: () => void;
  supervisor: any;
  onSave: (values: any) => void;
};

const EditSupervisorDialog: React.FC<EditSupervisorDialogProps> = ({ open, onClose, supervisor, onSave }) => {
  const initialValues = {
    userId: supervisor.userId,
    name: supervisor.user.name,
    department: supervisor.user.department,
    jobTitle: supervisor.user.jobTitle,
    supervisees: supervisor.supervisees.map((supervisee: SuperviseeType) => ({
      name: supervisee.name,
      department: supervisee.department,
      jobTitle: supervisee.jobTitle,
      userId: supervisee.userId
    }))
  };
  console.log("Edit Supervisor",initialValues);
  const handleSubmit = async (values: any) => {
    const supervisees: SuperviseeType[] = values.supervisees.map((supervisee:SuperviseeType) => ({
      name: supervisee.name,
      department: supervisee.department,
      jobTitle: supervisee.jobTitle,
      userId: supervisee.userId,  
    }));
    const supervisorData: SupervisorPartial = {
      id: supervisor.id,
      userId: supervisor.userId,
      supervisees: supervisees,
    };
    console.log("+++++++supervisorData++++++++",supervisorData)
    onSave(supervisorData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <Stack spacing={4} width="100%" p={2}>
                <CreateSupervisorForm editing />
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Stack>
            </Form>
          </Formik>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditSupervisorDialog;

import React, { useState } from 'react';
import {
  useGetItemsQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const ItemsList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');

  const { data: items, isLoading, isError } = useGetItemsQuery({
    skip: page * pageSize,
    limit: pageSize,
  });

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as string);
    setPage(0);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching items.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Items
      </Typography>



     
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell>Quantity Unit</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Unit Cost(USD)</TableCell>
              <TableCell>Item Category</TableCell>
              <TableCell>Inventory Group</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items && items.length > 0 ? (
              items.map((item: any) => (
                <TableRow key={item.id}>
                  <TableCell>{item.itemCode}</TableCell>
                  <TableCell>{item.quantityUnit}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.itemCost}</TableCell>
                  <TableCell>{item.itemCategory?.categoryCode || 'N/A'},{item.itemCategory?.description || 'N/A'}</TableCell>
                  <TableCell>{item.inventory?.inventoryCode || 'N/A'},{item.inventory?.description || 'N/A'}</TableCell>
                  <TableCell>
                    <Button>Edit</Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No matching items found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>

    
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
          disabled={page === 0}
          variant="contained"
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>
        <Typography variant="body1">Page {page + 1}</Typography>
        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={!items || items.length < pageSize}
          variant="contained"
          sx={{ marginLeft: 2 }}
        >
          Next
        </Button>

      </Box>

    
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          Rows per page:
        </Typography>
        <FormControl variant="outlined" size="small">
          <Select value={pageSize} onChange={handlePageSizeChange} sx={{ backgroundColor: '#f0f4f7' }}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ItemsList;

import React from 'react';
import { Autocomplete, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import itemFormModel from '../models/createItemModel';
import { useGetPurchaseOrdersQuery } from '../../../redux/vmsApi';
import grvFormModel from '../models/createGrvModel';

const {
    formField: {
        purchaseOrderId,
        status
    },
} = grvFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateGRVForm: React.FC<OwnProps> = (props) => {
    const { data: purchaseOrders = [] } = useGetPurchaseOrdersQuery({});
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();

    return (
        <Stack width="100%" spacing={5}>
            <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Create'} GRV
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field {...status} type="hidden" />
                <Autocomplete
                    options={purchaseOrders}
                    getOptionLabel={(option) => `${option.purchaseOrderNumber}`}
                    value={
                        purchaseOrders.find((order) => order.id === values.purchaseOrderId) || null
                    }
                    onChange={(event, newValue) => {
                        setFieldValue(purchaseOrderId.name, newValue?.id || '');
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Purchase Order"
                            variant="outlined"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            error={
                                touched[purchaseOrderId.name] &&
                                Boolean(errors[purchaseOrderId.name])
                            }
                            helperText={
                                touched[purchaseOrderId.name] &&
                                errors[purchaseOrderId.name]
                                    ? errors[purchaseOrderId.name]
                                    : ''
                            }
                        />
                    )}
                    style={{ width: '100%' }}
                />
            </Stack>
        </Stack>
    );
};

export default CreateGRVForm;


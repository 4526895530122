import React from 'react';
import { Card, CardContent, Typography, Grid, Divider, Table, TableHead, TableRow, TableCell, TableBody, Chip, Accordion, AccordionSummary, AccordionDetails, Box, Tooltip, IconButton, Dialog, Button, Alert, AlertTitle, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Attachment, RequisitionItem } from '../../../redux/vmsApi';
import CompetitiveAnalysis from './competetiveAnalysis';
import ComparativeAnalysisTable from './comparativeAnalysis';


interface RenderRequisitionDetailsProps {
  procurementProcess: any;
  development: any;
  selectedSupplier: number | null;
  openDialog: string | null;
  handleDialogOpen: (url: string) => void;
  handleDialogClose: () => void;
  handleSelectSupplier: (index: number, quotation: any) => void;
  showQuotationForm: boolean;
  setShowQuotationForm: (show: boolean) => void;
  isInProcess: boolean;
}
const formatUSD = (amount: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
const RenderRequisitionDetails: React.FC<RenderRequisitionDetailsProps> = ({
  procurementProcess,
  development,
  selectedSupplier,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
  handleSelectSupplier,
  showQuotationForm,
  setShowQuotationForm,
  isInProcess
}) => {
  console.log("++++++development++++", development);
  const hasComments = Boolean(
    procurementProcess?.siteManagerReview?.comment ||
    procurementProcess?.projectManagerReview?.comment ||
    procurementProcess?.cpoReview?.comment ||
    procurementProcess?.pcReview?.comment ||
    procurementProcess?.pmReview?.comment ||
    procurementProcess?.proChairReview?.comment
  );
  const getAlertMessage = () => {
    switch (procurementProcess?.currentTask) {
      case "SiteManagerReviewPurchaseRequisition":
        return "In this task as the Site Manager you are required to review and approve  or request  for requisition amendment  in the textbox below.";

      case "CorrectPurchaseRequisition":
        return "In this task as the Site Agent you are required to adjust the or amendment a  requisition  as per the feedback provided by the reviewers.";

      case "ProjectManagerReviewPurchaseRequisition":
        return "In this task as the Project Manager you are required to review and approve  or request  for requisition amendment  in the textbox below.";

      // case "CPOReviewPurchaseRequisition":
      //   return "In this task as the Chief Procurement Officer you are required to review and approve  or request  for requisition amendment  in the textbox below.";
      case "PCToUploadQuotations":
        return "In this task as the Procurement Clerk either proceed and upload the supplier Quotations for review or reject and request a requisition amendment in the textbox below.";
      case "PMToReviewProcurementApplication":
        return "In this task you are required to review supplier information and quotations.You can do so by  clicking on the name of the supplier.";
      case "CPOPicksSupplier":
        return "In this task you are required to choose a supplier.Click on the name of the supplier to choose a supplier.";
      case "ProChairReviewsSupplierChoice":
        return "In this task as the ProChair you are required to review the chosen  supplier.Click on the name of the supplier to view  supplier details.";
      case "PMToIssuePurchaseOrder":
        return "In this task as the Procurement Manager you have to issue Purchase Order .";

      default:
        return "No action required";
    }
  };

  return (
    <Card
      sx={{
        margin: 2,
        padding: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 4,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
      }}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
          Requisition Details
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Number:</strong> {procurementProcess?.requisition.reqNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              <strong>Title:</strong> {procurementProcess?.requisition.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              <strong>Date:</strong> {new Date(procurementProcess?.initiatedDate).toLocaleDateString('en-US', {
                weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
              })}{' '}
              {new Date(procurementProcess?.initiatedDate).toLocaleTimeString('en-US', {
                hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
              })}
            </Typography>

          </Grid>
        </Grid>
        <br />
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Project Details
        </Typography>
        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Start Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              const matchingProject = development?.projects?.find(
                (p: { id: any }) => p.id === procurementProcess?.requisition?.project.id
              );

              if (matchingProject) {
                const renderStatusChip = (status: string) => {
                  switch (status) {
                    case 'Completed':
                      return <Chip label="Completed" color="success" sx={{ fontWeight: 'bold' }} />;
                    case 'Not Started':
                      return <Chip label="Not Started" color="default" sx={{ fontWeight: 'bold', backgroundColor: '#ffc107', color: 'black' }} />;
                    case 'In Progress':
                      return <Chip label="In Progress" color="warning" sx={{ fontWeight: 'bold' }} />;
                    default:
                      return <Chip label={status} sx={{ fontWeight: 'bold' }} />;
                  }
                };

                return (
                  <TableRow key={matchingProject.id}>
                    <TableCell>{matchingProject.name}</TableCell>
                    <TableCell>{matchingProject.description}</TableCell>
                    <TableCell>{renderStatusChip(matchingProject.status)}</TableCell>
                    <TableCell>{matchingProject.startDate}</TableCell>
                  </TableRow>
                );
              }

              return (
                <TableRow>
                  <TableCell colSpan={4}>No matching project found.</TableCell>
                </TableRow>
              );
            })()}
          </TableBody>
        </Table>

        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Requisition Item(s) Details
        </Typography>

        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Quantity</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Stand</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Procured</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Unit Price</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Total Price Per Item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(procurementProcess?.requisition?.items || []).map(
              (item: RequisitionItem, index: number) => (
                procurementProcess?.requisition?.items ? (
                  <TableRow key={index}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {item.quantity?.quantityValue} {item.quantity?.quantityUnit}
                    </TableCell>
                    <TableCell>{item.unit?.name}</TableCell>
                    <TableCell>
                      {item.procured ? (
                        <Chip label="Yes" color="success" />
                      ) : (
                        <Chip label="No" color="error" />
                      )}
                    </TableCell>
                    <TableCell>{item.rate}</TableCell>
                    <TableCell>{item.totalCostPerItem}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No matching project found.</TableCell>
                  </TableRow>
                )
              )
            )}
          </TableBody>
        </Table>


        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
          <Typography
            variant="h4"
            sx={{
              color: '#ff5722',
              fontWeight: 'bold',
              backgroundColor: '#fbe9e7',
              padding: 3,
              borderRadius: '10px',
              boxShadow: '0px 4px 12px rgba(255, 87, 34, 0.3)',
              display: 'inline-block',
            }}
          >
            Total Item(s) Price: ${procurementProcess?.requisition?.totalCost.toFixed(2)}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Requisition Documents
        </Typography>

        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Document Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementProcess?.requisition?.documents.length > 0 ? (
              procurementProcess?.requisition?.documents.map((document: Attachment, index: number) => (
                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#e8f0fe' } }}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDialogOpen(document.url)} sx={{ color: '#1976d2' }}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>No documents available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>


        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Requisition Reviewer's Comments
        </Typography>
        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date Reviewed</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Reviewer</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Comment</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Decision</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementProcess?.siteManagerReview?.comment && (
              <TableRow >
                <TableCell>
                  {new Date(procurementProcess?.siteManagerReview?.dateReviewed).toLocaleDateString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                  })}{' '}
                  {new Date(procurementProcess?.siteManagerReview?.dateReviewed).toLocaleTimeString('en-US', {
                    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })}

                </TableCell>
                <TableCell>
                  {procurementProcess?.siteManagerReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.siteManagerReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>

                <TableCell>
                  {procurementProcess?.siteManagerReview?.comment}
                </TableCell>

                <TableCell>
                  {procurementProcess.siteManagerReview.approved ? (
                    <Chip label="Approved" color="success" />
                  ) : (
                    <Chip label="Rejected" color="error" />
                  )}
                </TableCell>
              </TableRow>)}


            {procurementProcess?.projectManagerReview
              ?.comment && (
                <TableRow >
                  <TableCell>
                    {new Date(procurementProcess?.projectManagerReview
                      ?.dateReviewed).toLocaleDateString('en-US', {
                        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                      })}{' '}
                    {new Date(procurementProcess?.projectManagerReview
                      ?.dateReviewed).toLocaleTimeString('en-US', {
                        hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                      })}

                  </TableCell>
                  <TableCell>
                    {procurementProcess?.projectManagerReview
                      ?.user?.name ?? 'N/A'}
                    (
                    {procurementProcess?.projectManagerReview
                      ?.user?.roles?.join(', ') || 'No Roles'}
                    )
                  </TableCell>

                  <TableCell>
                    {procurementProcess?.projectManagerReview
                      ?.comment}
                  </TableCell>

                  <TableCell>
                    {procurementProcess.projectManagerReview
                      .approved ? (
                      <Chip label="Approved" color="success" />
                    ) : (
                      <Chip label="Rejected" color="error" />
                    )}
                  </TableCell>
                </TableRow>)}

            {procurementProcess?.cpoReview?.comment && (
              <TableRow>
                <TableCell>
                  {new Date(procurementProcess?.cpoReview?.dateReviewed).toLocaleDateString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                  })}{' '}
                  {new Date(procurementProcess?.cpoReview?.dateReviewed).toLocaleTimeString('en-US', {
                    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })}
                </TableCell>
                <TableCell>
                  {procurementProcess?.cpoReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.cpoReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.cpoReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.cpoReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.cpoReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {procurementProcess?.pcReview?.comment && (
              <TableRow>
                <TableCell>
                  {new Date(procurementProcess?.pcReview?.dateReviewed).toLocaleDateString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                  })}{' '}
                  {new Date(procurementProcess?.pcReview?.dateReviewed).toLocaleTimeString('en-US', {
                    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })}
                </TableCell>

                <TableCell>
                  {procurementProcess?.pcReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.pcReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.pcReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.pcReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.pcReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
                <TableCell>{procurementProcess?.pmReview?.dateReviewed}</TableCell>
              </TableRow>


            )}

            {procurementProcess?.pmReview?.comment && (
              <TableRow>
                <TableCell>
                  {new Date(procurementProcess?.pmReview?.dateReviewed).toLocaleDateString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                  })}{' '}
                  {new Date(procurementProcess?.pmReview?.dateReviewed).toLocaleTimeString('en-US', {
                    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })}
                </TableCell>

                <TableCell>
                  {procurementProcess?.pmReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.pmReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.pmReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.pmReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.pmReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {procurementProcess?.proChairReview?.comment && (
              <TableRow>
                <TableCell>
                  {new Date(procurementProcess?.siteManagerReview?.dateReviewed).toLocaleDateString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
                  })}{' '}
                  {new Date(procurementProcess?.proChairReview?.dateReviewed).toLocaleTimeString('en-US', {
                    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })}
                </TableCell>
                <TableCell>
                  {procurementProcess?.proChairReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.proChairReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.proChairReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.proChairReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.proChairReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {!hasComments && (
              <TableRow>
                <TableCell colSpan={4}>No comments from reviewers yet.</TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
        <br />

        {isInProcess && (
          <Alert
            severity="warning"
            icon={<WarningAmberIcon fontSize="large" />}
            sx={{
              backgroundColor: '#fff3e0',
              color: '#3A2D26',
              borderRadius: 2,
              padding: '16px',
              alignItems: 'center',
              display: 'flex',
            }}
          >

            <AlertTitle
              sx={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >

              {getAlertMessage()}
            </AlertTitle>
          </Alert>
        )}

        {procurementProcess?.pcReview?.quotations && (
          <>
            <Divider sx={{ marginY: 2 }} />
            <ComparativeAnalysisTable quotations={procurementProcess.pcReview.quotations} user={procurementProcess.pcReview.user} />
            <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
              {procurementProcess?.currentTask === "CPOPicksSupplier"
                ? "Select Supplier"
                : "Review Supplier Quotations"}
            </Typography>

            {procurementProcess.pcReview.quotations.map((quotation: any, index: number) => (
              <Accordion
                key={index}
                expanded={selectedSupplier === index || quotation.selected}
                sx={{
                  backgroundColor: selectedSupplier === index ? '#e3f2fd' : '#f0f4fa',
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  marginBottom: 2,
                }}
              >
                <Tooltip
                  title={
                    procurementProcess?.currentTask === "PMToReviewProcurementApplication" ||
                      procurementProcess?.currentTask === "ProChairReviewsSupplierChoice"
                      ? "Click to view supplier details & Quotations"
                      : procurementProcess?.currentTask === "CPOPicksSupplier"
                        ? "Click to pick a supplier"
                        : "Click to select supplier"
                  }
                >
                  <AccordionSummary
                    onClick={() => {
                      if (procurementProcess?.currentTask === "CPOPicksSupplier") {
                        handleSelectSupplier(index, quotation);
                      } else if (!quotation.selected) {
                        handleSelectSupplier(index, quotation);
                      }
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: '#1976d2',
                      color: 'white',
                      borderRadius: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                      {quotation.supplier.name}
                    </Typography>

                    {procurementProcess?.currentTask === "CPOPicksSupplier" ? (
                      selectedSupplier === index && (
                        <Badge color="secondary" variant="dot">
                          <CheckCircleIcon sx={{ color: '#F7C914', marginLeft: 2, fontSize: '2rem' }} />
                        </Badge>
                      )
                    ) : (
                      quotation.selected && (
                        <Badge color="secondary" variant="dot">
                          <CheckCircleIcon sx={{ color: '#F7C914', marginLeft: 2, fontSize: '2rem' }} />
                        </Badge>
                      )
                    )}
                  </AccordionSummary>
                </Tooltip>
                <AccordionDetails>
                  <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                      <strong>Payment Terms:</strong> {quotation.paymentTerms}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                      <strong>Remarks:</strong> {quotation.remarks}
                    </Typography>
                  </Box>

                  <Table sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: 1 }}>
                    <TableBody>
                      {quotation?.attachments?.map((attachment: any, i: any) => (
                        <TableRow key={i} sx={{ '&:hover': { backgroundColor: '#e8f0fe' } }}>
                          <TableCell>{attachment.name}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleDialogOpen(attachment.url)} sx={{ color: '#1976d2' }}>
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Table
                    sx={{
                      width: '100%',
                      backgroundColor: '#f9f9f9',
                      borderRadius: 1,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      overflow: 'hidden',
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#1976d2' }}>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Description</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Discount</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Quantity</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Unit</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Rate</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Total Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quotation?.quotationItems?.map((item: any, i: number) => (
                        <TableRow
                          key={i}
                          sx={{
                            '&:hover': { backgroundColor: '#e3f2fd' },
                            '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                          }}
                        >
                          <TableCell>{item.description || 'N/A'}</TableCell>
                          <TableCell>{item.discountPerItem || 'N/A'}</TableCell>
                          <TableCell>{item.quantity?.quantityValue || 'N/A'}</TableCell>
                          <TableCell>{item.quantityUnit || 'N/A'}</TableCell>
                          <TableCell>{item.rate || 'N/A'}</TableCell>
                          <TableCell>{formatUSD(item.totalCostPerItem || 'N/A')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Table
                    sx={{
                      width: '50%',
                      marginTop: 2,
                      marginLeft: 'auto',
                      marginRight: 0,
                      backgroundColor: '#f9f9f9',
                      borderRadius: 1,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    <TableBody>
                      <TableRow sx={{ '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>Total Before VAT</TableCell>
                        <TableCell>

                          {formatUSD(quotation?.totalb4VAT || 'N/A')}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>Total VAT</TableCell>
                        <TableCell>{formatUSD(quotation?.totalVAT || 'N/A')}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>Grand Total</TableCell>
                        <TableCell>{formatUSD(quotation?.grandTotal || 'N/A')}</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>

                  <Table
                    sx={{
                      width: '50%',
                      marginTop: 2,
                      marginLeft: 'auto',
                      marginRight: 0,
                      backgroundColor: '#32CD32',
                      borderRadius: 1,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    <TableBody>

                      <TableRow sx={{ '&:hover': { backgroundColor: '#F7C914' } }}>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Total Discount</TableCell>
                        <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>{formatUSD(quotation?.totalDiscount || 'N/A')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>



                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}




        <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
          <Box p={2}>
            {openDialog && (
              <iframe
                src={openDialog}
                title="Document Viewer"
                width="100%"
                height="500px"
                style={{ border: 'none' }}
              />
            )}
            <Box textAlign="right" mt={2}>
              <Button onClick={handleDialogClose} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>


      </CardContent>
    </Card>
  );
};

export default RenderRequisitionDetails;

const itemTransferFormModel = {
    formId: 'itemTransferForm',
    formField: {
        items: {
            name: 'items',
            label: 'Items',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                itemId: {
                    name: 'itemId',
                    label: 'Item',
                },
                itemCode: {
                    name: 'itemCode',
                    label: 'Item Code',
                },
                description: {
                    name: 'description',
                    label: 'description',
                },
                itemCost: {
                    name: 'itemCost',
                    label: 'ItemCost',
                },
                quantity: {
                    name: 'quantity',
                    label: 'Quantity',
                    required: true,
                    requiredErrorMsg: 'Quantity is required',
                    quantityValue: {
                        name: 'quantityValue',
                        label: 'Quantity',
                        required: true,
                        requiredErrorMsg: 'Quantity is required',
                    },
                    quantityUnit: {
                        name: 'quantityUnit',
                        label: 'Unit',
                        required: true,
                        requiredErrorMsg: 'Unit is required',
                    }
                },
                reasonForTransfer: {
                    name: 'reasonForTransfer',
                    label: 'Reason For Transfer',
                    required: true,
                    requiredErrorMsg: 'Reason For Transfer is required',
                }
            },
        },

        sourceLocationType: {
            name: 'sourceLocationType',
            label: 'Source Location Type',
            required: true,
            requiredErrorMsg: 'Source Location Type is required',
        },
        destinationLocationType: {
            name: 'destinationLocationType',
            label: 'Destination Location Type',
            required: true,
            requiredErrorMsg: 'Destination Locaction Type is required',
        },
        sourceLocation: {
            name: 'sourceLocation',
            label: 'SourceLocation',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                development: {
                    name: 'development',
                    label: 'development',
                },
                project: {
                    name: 'project',
                    label: 'Project',


                },
                unit: {
                    name: 'unit',
                    label: 'Unit',
                },
                supplier: {
                    name: 'supplier',
                    label: 'Supplier',
                    required: true,

                },
                inventoryLocation: {
                    name: 'Inventory location',
                    label: 'Inventory Location',
                    required: true,
                },
            },
        },
        destinationLocation: {
            name: 'destinationLocation',
            label: 'DestinationLocation',
            required: true,
            requiredErrorMsg: ' Destination is required',
            fields: {
                development: {
                    name: 'development',
                    label: 'Development',
                },
                project: {
                    name: 'project',
                    label: 'Project',
                    required: true,

                },
                unit: {
                    name: 'unit',
                    label: 'Unit',
                    required: true,

                },
                supplier: {
                    name: 'supplier',
                    label: 'Supplier',
                    required: true,
                    requiredErrorMsg: 'Supplier is required',
                },
                inventoryLocation: {
                    name: 'location',
                    label: 'Inventory Location',
                    required: true,
                }
            },
        }

    },
    initialValues: {
        items: [{
            itemId: '',
            itemCode: '',
            description: '',
            itemCost: '',
            quantity: {
                quantityValue: 0,
                quantityUnit: ''
            },
            reasonForTransfer: '',
        }],       
        
        sourceLocationType: '',
        destinationLocationType: '',
        sourceLocation: {
            development: { id: '', name: '' },
            project: { id: '', name: '' },
            unit: { id: '', name: '' },
            supplier: { id: '', name: '' },
            inventoryLocation: { id: '', locationCode: '', description: '', developmentId: '' }
        },
        destinationLocation: {
            development: { id: '', name: '' },
            project: { id: '', name: '' },
            unit: { id: '', name: '' },
            supplier: { id: '', name: '' },
            inventoryLocation: { id: '', locationCode: '', description: '', developmentId: '', developmentName: '' }
        },

    },
};


export default itemTransferFormModel;

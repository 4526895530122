const grvFormModel = {
    formId: 'grvForm',
    formField: {
        purchaseOrderId: {
            name: 'purchaseOrderId',
            label: 'Purchase Order Number',
            required: true,
            requiredErrorMsg: 'Purchase Order is required',
        },
        date: {
            name: 'date',
            label: 'Date',
            required: true,
            requiredErrorMsg: 'Date is required',
        },
        grvNumber: {
            name: 'grvNumber',
            label: 'Grv Number',
            required: true,
            requiredErrorMsg: 'Grv Number is required',
        },
        status: {
            name: 'status',
            label: 'Status',
            required: true,
            requiredErrorMsg: 'Status is required',
        },
    },
    initialValues: {
        purchaseOrderId: '',
        status: true,
        grvNumber: '',
    },
};

export type createGrvModelType = {
    purchaseOrderId: string;
    status: boolean;
    grvNumber: string;
};

export default grvFormModel;

import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const CompetitiveAnalysis = ({ quotations }: { quotations: any[] }) => {
  const [analysisData, setAnalysisData] = useState<any>(null);
  const [showAnalysis, setShowAnalysis] = useState(false);

  const generateAnalysis = () => {
    const itemsAnalysis: any = {};
    quotations.forEach((quotation) => {
      quotation.quotationItems.forEach((item: any) => {
        const { description, rate, totalCostPerItem, discountPerItem, quantity } = item;

        
        const quantityValue = quantity?.quantityValue || 1;
        const quantityUnit = quantity?.quantityUnit || "";

        const itemData = {
          supplier: quotation.supplier.name,
          rate: parseFloat(rate) || 0,
          discount: parseFloat(discountPerItem) || 0,
          totalCost: parseFloat(totalCostPerItem) || 0,
          quantityValue,
          quantityUnit,
        };

        if (!itemsAnalysis[description]) {
          itemsAnalysis[description] = [];
        }

        itemsAnalysis[description].push(itemData);
      });
    });

    setAnalysisData(itemsAnalysis);
    setShowAnalysis(true);
  };

  const getBestSupplier = (suppliers: any[]) => {
    const filteredSuppliers = suppliers.filter((s: any) => s.totalCost > 0);
    const minCost = Math.min(...filteredSuppliers.map((s: any) => s.totalCost));
    return filteredSuppliers.find((s: any) => s.totalCost === minCost)?.supplier;
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={generateAnalysis}
        sx={{
          marginBottom: 2,
          backgroundColor: "gold",
          color: "black",
          "&:hover": { backgroundColor: "yellow" },
        }}
      >
        Analyze
      </Button>

      {showAnalysis && analysisData && (
        <TableContainer component={Paper} sx={{ marginTop: 3, borderRadius: 2 }}>
          <Box sx={{ padding: 2, backgroundColor: "#1976d2", color: "white" }}>
            <Typography variant="h6" align="center">
              Competitive Analysis
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Item Description</TableCell>
                {quotations.map((q, index) => (
                  <TableCell key={index} sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {q.supplier.name}
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Recommended Supplier
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(analysisData).map(([description, suppliers]: any, index) => {
                const filteredSuppliers = suppliers.filter((s: any) => s.totalCost > 0);
                const minCost = Math.min(...filteredSuppliers.map((s: any) => s.totalCost));
                const maxCost = Math.max(...suppliers.map((s: any) => s.totalCost));
                const bestSupplier = getBestSupplier(suppliers);

                return (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "bold" }}>{description}</TableCell>
                    {suppliers.map((s: any, i: number) => (
                      <TableCell
                        key={i}
                        sx={{
                          textAlign: "center",
                          backgroundColor:
                            s.totalCost === 0
                              ? "amber"
                              : s.totalCost === minCost
                              ? "lightgreen"
                              : s.totalCost === maxCost
                              ? "lightcoral"
                              : "inherit",
                          borderRadius: "4px",
                          padding: 1,
                        }}
                      >
                        {s.totalCost === 0 ? (
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <WarningAmberIcon sx={{ color: "orange", marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: "bold", color: "orange" }}>
                              Missing
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                              Total: ${s.totalCost.toFixed(2)}
                            </Typography>
                            <Typography variant="caption">
                              Rate: ${s.rate.toFixed(2)} | Discount: ${s.discount.toFixed(2)}
                            </Typography>
                            <Typography variant="caption" sx={{ display: "block" }}>
                              Quantity: {s.quantityValue} {s.quantityUnit}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                    <TableCell sx={{ textAlign: "center", fontWeight: "bold", color: "green" }}>
                      {bestSupplier}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CompetitiveAnalysis;

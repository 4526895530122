import React from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import LocationFormModel from '../models/createInventoryLocationModel';
import { useGetDevelopmentsQuery } from '../../../redux/vmsApi';


const {
    formField: {
        developmentId,
        locationCode,
        description,
    },
} = LocationFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateInventoryLocationForm: React.FC<OwnProps> = (props) => {
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();
    const { data: developments = [] } = useGetDevelopmentsQuery();


    return (
        <Stack width="100%" spacing={5}>
            <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'} Location
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field
                    as={TextField}
                    fullWidth
                    {...locationCode}
                    error={touched[locationCode.name] && Boolean(errors[locationCode.name])}
                    helperText={touched[locationCode.name] && errors[locationCode.name] ? errors[locationCode.name] : ''}
                />
                <Field
                    as={TextField}
                    fullWidth
                    select

                    {...developmentId}
                    value={values.developmentId || ''}

                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                    error={touched[developmentId.name] && Boolean(errors[developmentId.name])}
                    helperText={touched[developmentId.name] && errors[developmentId.name] ? errors[developmentId.name] : ''}
                >
                    {developments.map((development) => (
                        <MenuItem key={development.id} value={development.id}>
                            {development.name}
                        </MenuItem>
                    ))}
                </Field>


                <Field
                    as={TextField}
                    fullWidth
                    {...description}
                    error={touched[description.name] && Boolean(errors[description.name])}
                    helperText={touched[description.name] && errors[description.name] ? errors[description.name] : ''}
                />
            </Stack>


        </Stack>
    );
};

export default CreateInventoryLocationForm;

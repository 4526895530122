import React from 'react';

import {Paper, Typography} from "@mui/material";
import PageWithHeader from "../common/PageWithHeader";
import {useGetClientsQuery} from "../../redux/vmsApi";
import CreateSupervisorComponent from './CreateSupervisorComponent';
import SupervisorsList from './SupervisorsList';

const SupervisorsPage: React.FC = () => {
  const {data: clients, isLoading} = useGetClientsQuery({});


  return (
    <PageWithHeader padding={5}>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
       Assign Employees To a Supervisor
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateSupervisorComponent />
        <SupervisorsList/>
      </Paper>
      <Paper sx={{my: 5}}>
        <SupervisorsList/>
      </Paper>
    </PageWithHeader>
  )
}

export default SupervisorsPage;
const ItemCategoryFormModel = {
    formId: 'ItemCategoryForm',
    formField: {
      description: {
        name: 'description',
        label: 'Description',
        required: true,
        requiredErrorMsg: 'Description is required',
      },
      
      categoryCode: {
        name: 'categoryCode',
        label: ' Category Code',
        required: true,
        requiredErrorMsg: 'Category Code is required',
      },
    },
    initialValues: {
        categoryCode: '',
        description:'',
    },
  };
  
  export type createItemCategoryModelType = {
    categoryCode: string;
    description: string;
  };
  
  export default ItemCategoryFormModel;
  
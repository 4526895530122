import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Box, Button, Paper, Typography} from "@mui/material";
import {push} from "connected-react-router/immutable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import FeedbackSurveyResponses from "./FeedbackSurveyResponses";

interface OwnProps {
  match?: any;
}

const FeedbackSurveyReview: React.FC<OwnProps> = (props) => {
  const {match} = props;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.auth.user);


  return (
    <PageWithHeader>
      <Box padding={5}>
        <Button color="primary" onClick={() => dispatch(push('/reviews'))}>
          <ArrowBackIcon/>
          <Typography color="primary" align="left">Dashboard</Typography>
        </Button>
        <Paper elevation={3}>
          <FeedbackSurveyResponses subjectUserId={currentUser?.id ?? ''} />
        </Paper>
      </Box>
    </PageWithHeader>
  )
}

export default FeedbackSurveyReview;
const supervisorFormModel = {
    formId: 'supervisorForm',
    formField: {
        userId: {
            name: 'userId',
            label: 'name',
           
        },

        name: {
            name: 'name',
            label: 'name',
            required: true,
            requiredErrorMsg: 'Name is required',
        },
        department: {
            name: 'department',
            label: 'department',
            required: true,
            requiredErrorMsg: 'Department is required',
        },
        jobTitle: {
            name: 'jobTitle',
            label: 'jobTitle',
            
        },
              
        supervisees: {
            name: 'supervisees',
            label: ' supervisees',
            required: true,
            requiredErrorMsg: 'At least one supervisee is required',
            superviseeFields: {
                userId: {
                    name: 'userId',
                    label: 'name',
                   
                },
                 name: {
                   name: 'name',
                   label: 'name',
                  
                 },
                 department: {
                    name: 'department',
                    label: 'department',
                   
                  },
                 jobTitle: {
                    name: 'jobTitle',
                    label: 'jobTitle'
                 },
                 
        },
        
            
        },
    },
    initialValues: {
        userId:'',
        name: '',
        department:'',
        jobTitle:'',  
        supervisees: [{
            userId:'',
            name: '',
            department: '',
            jobTitle: '',
        }],
    },
};

export type SuperviseeType = {
    name: string;
    department:string;
    jobTitle:string;
    userId:string;
};



export type createSupervisorModelType = {
    userId:string;
    name: string;
    department:string;
    jobTitle:string;
    supervisees:SuperviseeType[]
};


export default supervisorFormModel;

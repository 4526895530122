import {DepositPayment} from "../../redux/vmsApi";

const createAgreementModel = {
    formId: 'createInvestmentAgreementForm',
    formField: {
        clientCompanyName: {
            name: 'clientCompanyName',
            label: 'Client Company Name',
            required: true,
            requiredErrorMsg: 'Client company name is required',
            invalidErrorMsg: 'Invalid entry'
        },
        clientName: {
            name: 'clientName',
            label: 'Client Name',
            required: true,
            requiredErrorMsg: 'Client name is required',
            invalidErrorMsg: 'Invalid name'
        },
        clientIdNumber: {
            name: 'clientIdNumber',
            label: 'Client ID Number(s)',
            required: true,
            requiredErrorMsg: 'Client id number is required',
            invalidErrorMsg: 'Invalid id number'
        },
        clientAddress: {
            name: 'clientAddress',
            label: 'Client Service Address',
            required: true,
            requiredErrorMsg: 'Client service address is required',
            invalidErrorMsg: 'Invalid client address'
        },
        company: {
            name: 'company',
            label: 'Development',
            required: true,
            requiredErrorMsg: 'Company is required',
            invalidErrorMsg: 'Invalid company selected'
        },
        representedBy: {
            name: 'representedBy',
            label: 'Company Representative',
            required: true,
            requiredErrorMsg: 'Company Representative is required',
            invalidErrorMsg: 'Invalid Company Representative selected'
        },
        purchasePrice: {
            name: 'purchasePrice',
            label: 'Purchase Price',
            required: false,
            requiredErrorMsg: 'Purchase price is required',
            invalidErrorMsg: 'Invalid purchase price entered'
        },
        unitNumber: {
            name: 'unitNumber',
            label: 'Unit Number',
            required: true,
            requiredErrorMsg: 'Unit number is required',
            invalidErrorMsg: 'Invalid unit number entered'
        },
        landShare: {
            name: 'landShare',
            label: 'Land Share Number',
            required: true,
            requiredErrorMsg: 'Land share number is required',
            invalidErrorMsg: 'Land share number entered'
        },
        block: {
            name: 'block',
            label: 'Block',
            required: false,
            requiredErrorMsg: 'Block is required',
            invalidErrorMsg: 'Invalid block entered'
        },
        phase: {
            name: 'phase',
            label: 'Phase',
            required: false,
            requiredErrorMsg: 'Phase is required',
            invalidErrorMsg: 'Invalid phase entered'
        },
        paymentsToDate: {
            name: 'paymentsToDate',
            label: 'Payments to Date',
            required: false,
            requiredErrorMsg: 'Payments to date are required',
            invalidErrorMsg: 'Invalid payments to date entered'
        },
        firstInstallmentPaymentDate: {
            name: 'firstInstallmentPaymentDate',
            label: 'First Installment Payment Date',
            required: false,
            requiredErrorMsg: 'First Installment Payment Date is required',
            invalidErrorMsg: 'Invalid First Installment Payment Date entered'
        },
        lastInstallmentPaymentDate: {
            name: 'lastInstallmentPaymentDate',
            label: 'Last Installment Payment Date',
            required: false,
            requiredErrorMsg: 'Last Installment Payment Date is required',
            invalidErrorMsg: 'Invalid Last Installment Payment Date entered'
        },
        numberOfInterestFreeMonths: {
            name: 'numberOfInterestFreeMonths',
            label: 'Number of Interest Free Months',
            required: true,
            requiredErrorMsg: 'No of interest free months is required',
            invalidErrorMsg: 'Invalid no of interest free months entered'
        },
        propertyDescription: {
            name: 'propertyDescription',
            label: 'Property Description',
            required: true,
            requiredErrorMsg: 'Property Description is required',
            invalidErrorMsg: 'Invalid Property Description'
        },
        unitArea: {
            name: 'unitArea',
            label: 'Unit Area',
            required: true,
            requiredErrorMsg: 'Unit area is required',
            invalidErrorMsg: 'Invalid unit area'
        },
        chargeInterest: {
            name: 'chargeInterest',
            label: 'Charge Interest?',
        },
        contractReference: {
            name: 'contractReference',
            label: 'Contract Reference',
            required: true,
            requiredErrorMsg: 'Contract reference is required',
            invalidErrorMsg: 'Contract reference unit area'
        },
        clientDob: {
            name: 'clientDob',
            label: 'Client DOB',
            required: true,
            requiredErrorMsg: 'Client DOB is required',
            invalidErrorMsg: 'Invalid DOB'
        },
    },
    initialValues: {
        clientCompanyName: '',
        clientName: '',
        clientIdNumber: '',
        clientAddress: '',
        company: 'seatrite',
        representedBy: 'tatiana',
        purchasePrice: 0,
        unitNumber: '',
        landShare: '',
        block: 'Block 4',
        phase: '1A',
        paymentsToDate: [],
        firstInstallmentPaymentDate: null,
        lastInstallmentPaymentDate: null,
        numberOfInterestFreeMonths: 0,
        propertyDescription: 'Studio',
        unitArea: 0,
        chargeInterest: true,
        contractReference: '',
        clientDob: '',
    }
};

export type createInvestmentAgreementModelType = {
    clientCompanyName: string,
    clientName: string,
    clientIdNumber: string,
    clientAddress: string,
    company: string,
    representedBy: string,
    purchasePrice: number,
    unitNumber: string,
    landShare: string,
    block: string,
    phase: string,
    paymentsToDate: DepositPayment[],
    firstInstallmentPaymentDate: string | null,
    lastInstallmentPaymentDate: string | null,
    numberOfInterestFreeMonths: number,
    propertyDescription: string,
    unitArea: number,
    chargeInterest: boolean,
    contractReference: string;
    clientDob: string;
}

export default createAgreementModel
  

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Chip,
    Dialog,
    IconButton,


} from '@mui/material';
import {
    ProcurementProcessWithRelations,
    useGetProcurementProcessesQuery,
    useGetUsersMeQuery,

} from '../../../redux/vmsApi';
import { useState } from 'react';
import React from 'react';
import RequisitionsFilter from '../common/RequisitionsFilter';
const camelCaseToSpaced = (text: string) => text.replace(/([A-Z])/g, ' $1').trim();
const removeHyphens = (text: string) => text.replace(/-/g, ' ');

const RequisitionsList: React.FC = () => {
    const [filters, setFilters] = useState({
        title: '',
        approved: undefined,
        development: '',
        reqNumber: '',
        fromDate: '2024-10-01',
        toDate: '2025-12-20',
        currentTask: 'all',
    });
    const [openDialog, setOpenDialog] = useState<string | null>(null);
    const handleDialogOpen = (url: string) => setOpenDialog(url);
    const handleDialogClose = () => setOpenDialog(null);
    const [currentOpenRow, setCurrentOpenRow] = useState<string | null>(null);

    const handleToggleDropdown = (rowId: string) => {
        setCurrentOpenRow((prev) => (prev === rowId ? null : rowId));
    };



    const handleViewProcessDetails = (procurementProcessId: string | undefined) => {
        window.location.href = `http://localhost:3001/procurement/procurementProcess/${procurementProcessId}`;
        setCurrentOpenRow(null);
    };

    const [apiParams, setApiParams] = useState({ ...filters, skip: 0, limit: 10 });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const { data: user } = useGetUsersMeQuery();
    const { data: procurementProcesses, isLoading, isError } = useGetProcurementProcessesQuery(apiParams);

    const handleFilterChange = (field: string, value: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleApplyFilters = () => {
        setApiParams({
            ...filters,
            skip: 0,
            limit: pageSize,
        });
        setPage(0);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        setApiParams((prevParams) => ({
            ...prevParams,
            skip: newPage * pageSize,
        }));
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setPage(0);
        setApiParams((prevParams) => ({
            ...prevParams,
            limit: newSize,
            skip: 0,
        }));
    };

    const handleNavigate = (task: string, id: string) => {
        window.location.href = `/procurement/${task}/${id}`;
    };

    const isButtonDisabled = (currentTaskOwner: string | undefined) => {
        if (user?.roles?.includes('admin')) {
            return false;
        }
        return !user?.roles?.includes(currentTaskOwner || '');
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching Procurement Processes.</div>;
    }

    return (
        <React.Fragment>
            <RequisitionsFilter
                filters={filters}
                onFilterChange={handleFilterChange}
                onApplyFilters={handleApplyFilters}
            />

            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#1976d2' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Number</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="left">Title</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Requisition Raised By</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Date Raised</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Development</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Approved</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">View Document</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {procurementProcesses && procurementProcesses.length > 0 ? (
                            procurementProcesses.map((procurementProcess: ProcurementProcessWithRelations, index: number) => (
                                <TableRow key={procurementProcess.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                                    <TableCell component="th" scope="row">
                                        {procurementProcess.requisition?.reqNumber || 'N/A'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {procurementProcess.requisition?.title || 'N/A'}
                                    </TableCell>

                                    <TableCell align="right">
                                        {procurementProcess.initiatedByUser?.name || 'N/A'}
                                    </TableCell>
                                    <TableCell align="right">
                                        {procurementProcess.initiatedDate ? new Date(procurementProcess.initiatedDate).toLocaleDateString() : 'N/A'}
                                    </TableCell>

                                    <TableCell align="right">
                                        {procurementProcess.requisition?.development?.name || 'N/A'}, {procurementProcess.requisition?.project?.name || 'N/A'}
                                    </TableCell>

                                    <TableCell>
                                        {procurementProcess.requisition.approved ? (
                                            <Chip label="Approved" color="success" />
                                        ) : (
                                            <Chip label="Not Yet Approved" color="error" />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDialogOpen(procurementProcess.requisitionDocument?.url || '')} sx={{ color: '#1976d2' }}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ position: "relative", display: "inline-block" }}>
                                            <Button
                                                sx={{
                                                    backgroundColor: "primary.main",
                                                    color: "white",
                                                    "&:hover": { backgroundColor: "primary.dark" },
                                                }}
                                                onClick={() => handleToggleDropdown(procurementProcess.id || "")}
                                            >
                                                Actions
                                            </Button>
                                            {currentOpenRow === procurementProcess.id && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: 0,
                                                        zIndex: 1,
                                                        mt: 1,
                                                        minWidth: "150px",
                                                        backgroundColor: "white",
                                                        boxShadow: 3,
                                                        borderRadius: 1,
                                                        overflow: "hidden",
                                                    }}
                                                >

                                                    <Button
                                                        sx={{
                                                            display: "block",
                                                            width: "100%",
                                                            textAlign: "left",
                                                            px: 2,
                                                            py: 1,
                                                            "&:hover": { backgroundColor: "grey.100" },
                                                        }}
                                                        onClick={() => handleViewProcessDetails(procurementProcess.id)}
                                                    >
                                                        Process
                                                    </Button>

                                                </Box>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">No matching Procurement Processes found.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>


            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Button
                    onClick={() => handlePageChange(Math.max(page - 1, 0))}
                    disabled={page === 0}
                    variant="contained"
                    sx={{ marginRight: 2 }}
                >
                    Previous
                </Button>

                <Typography variant="body1">Page {page + 1}</Typography>

                <Button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={procurementProcesses?.length === undefined || procurementProcesses.length < pageSize}
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                >
                    Next
                </Button>
            </Box>


            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
                <FormControl variant="outlined" size="small">
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        sx={{ backgroundColor: '#f0f4f7' }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
                <Box p={2}>
                    {openDialog && (
                        <iframe
                            src={openDialog}
                            title="Document Viewer"
                            width="100%"
                            height="500px"
                            style={{ border: 'none' }}
                        />
                    )}
                    <Box textAlign="right" mt={2}>
                        <Button onClick={handleDialogClose} variant="contained" color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>


    );
};

export default RequisitionsList;

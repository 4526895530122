import React from 'react';
import {Paper, Typography} from "@mui/material";


import ProcurementContainer from '../containers/ProcurementContainer';

import PurchaseOrderList from '../lists/PurchaseOrdersList';

const PurchaseOrdersPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
       Purchase Orders
      </Typography>
      <Paper sx={{my: 5}}>
        
      </Paper>
      <Paper sx={{my: 5}}>
       <PurchaseOrderList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default PurchaseOrdersPage;
import React, { useState } from 'react';
import {Button, Card, CardContent, Collapse, IconButton, Skeleton, styled, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { User, useGetEmployeeReviewsByUserByUserIdQuery, useGetLifeWheelsByUserByUserIdQuery } from "../../redux/vmsApi";
import { push } from "connected-react-router/immutable";
import { useAppDispatch } from "../../redux/hooks";

interface OwnProps {
  userData: User;
}

interface ExpandMoreProps {
  expand: boolean;
}

const ExpandMore = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'expand',
})<ExpandMoreProps>(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const EmployeeCard: React.FC<OwnProps> = ({ userData }) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);

  const { data: spearReports, isLoading: spearIsLoading } = useGetEmployeeReviewsByUserByUserIdQuery(
    {userId: userData.id ?? ''},
    { skip: !expanded }
  );

  const { data: lifeWheelReports, isLoading: lifeWheelIsLoading } = useGetLifeWheelsByUserByUserIdQuery(
    {userId: userData.id ?? ''},
    { skip: !expanded }
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ marginBottom: 5 }}>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {(!userData.name || spearIsLoading || lifeWheelIsLoading) ? (
          <Skeleton variant="rectangular" width={210} height={20} />
        ) : (
          <Typography variant="h6">{userData.name}</Typography>
        )}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>SPEAR:</Typography>
          {spearReports && spearReports.map((report, index) => (
            <Button key={index} onClick={() => dispatch(push(`/reviews/admin/spear/${report.id}`))}>
              {report.reviewPeriod}
            </Button>
          ))}
          <Typography paragraph>Life Wheel:</Typography>
          {lifeWheelReports && lifeWheelReports.map((report, index) => (
            <Button key={index} onClick={() => dispatch(push(`/reviews/admin/life-wheel/${report.id}`))}>
              {report.reviewPeriod}
            </Button>
          ))}
          <Typography paragraph>
            <Button onClick={() => dispatch(push(`/reviews/admin/360/${userData.id}`))}>
              View 360 Reviews
            </Button>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default EmployeeCard;

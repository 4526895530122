import { User } from "../../../redux/vmsApi";

const requisitionReviewFormModel = {
  formId: 'requisitionReviewForm',
  formField: {
    comment: {
      name: 'comment',
      label: 'Comment',
      required: true,
      requiredErrorMsg: 'Name is required',
    },
    approve: {
      name: 'approve',
      label: 'Approve',
      required: false,
    },
    
    quotations: {
      name: 'quotations',
      label: 'Quotations',
      required: true,
      requiredErrorMsg: 'At least one item is required',
      fields: {
        paymentTerms: {
          name: 'paymentTerms',
          label: 'Payment Terms',
          required: false,
        },
        remarks: {
          name: 'remarks',
          label: 'Remarks',
          required: false,
        },
        supplier: {
          name: 'supplier',
          label: 'Supplier',
          required: true,
          requiredErrorMsg: 'Supplier is required',
        },

        totalVAT: {
          name: 'Total discount',
          label: 'discount',
          required: true,
          requiredErrorMsg: 'Discount is required',
        },
        totalDiscount: {
          name: 'Total discount',
          label: 'discount',
          required: true,
          requiredErrorMsg: 'Discount is required',
        },
        totalb4VAT: {
          name: 'Total discount',
          label: 'discount',
          required: true,
          requiredErrorMsg: 'Discount is required',
        },
        grandTotal: {
          name: 'Total discount',
          label: 'discount',
          required: true,
          requiredErrorMsg: 'Discount is required',
        },

        attachments: {
          name: 'attachments',
          label: 'Attachments',
          required: false,
        },

        quotationItems:{
          name: 'quotationItems',
          label: 'quotationItems',
          required: false,
        }
      },
    }
  },
  initialValues: {
    comment: '',
    user: {
      id: '',
      email: '',
      name: '',
      department: '',
      gender: '',
      supervisorId:'',
      jobTitle:'',
      idNumber:'',
      roles: []
    },
    approved: false,
    dateReviewed:new Date(),
    quotations: [{
      paymentTerms:'',
      remarks:'',
      supplier: '',
      attachments: [],
      totalDiscount:0,
      totalVAT:0,
      totalb4VAT:0,
      grandTotal:0,
      quotationItems:[],
      selected:true
    }]
  }
}

export type QuotationType = {
  supplier:{ id: string; name: string; };
  attachments: { name: string; url: string; }[]
  quotationItems:any[]
  paymentTerms: string;
  totalDiscount:number;
  totalVAT:number;
  totalb4VAT:number;
  grandTotal:number;
  remarks:string;
  selected:boolean;
};

export type RequisitionReviewType = {
  comment: string;
  dateReviewed: Date;
  approved: boolean;
  user?: User;
  quotations?: QuotationType[];
};

export default requisitionReviewFormModel;

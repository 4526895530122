import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

interface RequisitionsFilterProps {
    filters: any;
    onFilterChange: (field: string, value: string) => void;
    onApplyFilters: () => void;
}

const RequisitionsFilter: React.FC<RequisitionsFilterProps> = ({ filters, onFilterChange, onApplyFilters }) => {
    return (
        <Box
            component="div"
            sx={{
                padding: 3,
                borderRadius: 2,
                marginBottom: 3,
                backgroundColor: '#f9f9f9',
                boxShadow: 3,
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                <TextField
                    label="Title"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.title}
                    onChange={(e) => onFilterChange('title', e.target.value)}
                />
                <TextField
                    label="Requisition Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.reqNumber}
                    onChange={(e) => onFilterChange('reqNumber', e.target.value)}
                />
                <TextField
                    label="Development"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.development}
                    onChange={(e) => onFilterChange('development', e.target.value)}
                />
                <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
                    <InputLabel>Approved</InputLabel>
                    <Select
                        value={filters.approved}
                        onChange={(e) => onFilterChange('approved', e.target.value)}
                        label="Approved"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="true">Approved</MenuItem>
                        <MenuItem value="false">Rejected</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.fromDate}
                    onChange={(e) => onFilterChange('fromDate', e.target.value)}
                />
                <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    value={filters.toDate}
                    onChange={(e) => onFilterChange('toDate', e.target.value)}
                />
                <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Current Task</InputLabel>
                    <Select
                        value={filters.currentTask}
                        onChange={(e) => onFilterChange('currentTask', e.target.value)}
                        label="Current Task"
                        sx={{ maxWidth: 240, backgroundColor: '#fff', borderRadius: 1 }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="SiteManagerReviewPurchaseRequisition">Site Manager Review</MenuItem>
                        <MenuItem value="CorrectPurchaseRequisition">Correct Requisition</MenuItem>
                        <MenuItem value="ProjectManagerReviewPurchaseRequisition">Project Manager Review</MenuItem>
                        <MenuItem value="PCToUploadQuotations">Upload Quotations</MenuItem>
                        <MenuItem value="CPOPicksSupplier">Pick Supplier</MenuItem>
                        <MenuItem value="ProChairReviewsSupplierChoice">Review Supplier Choice</MenuItem>
                        <MenuItem value="StoresClerkToReceiveGoods">Receive Goods</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Stack direction="row" spacing={2} mt={2}>
                <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={onApplyFilters}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        color: '#1976d2',
                        borderColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#e3f2fd',
                            borderColor: '#1565c0',
                        },
                    }}
                >
                    Apply Filters
                </Button>
            </Stack>
        </Box>
    );
};

export default RequisitionsFilter;

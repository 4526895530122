import React, { useState } from 'react';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,

} from '@mui/material';
import {
  NewProcurementProcess,
  PostCreateProcurementProcessApiArg,
  ProcurementProcessWithRelations,
  useGetProcurementProcessesQuery,
  useGetUsersMeQuery,
  usePostCreateProcurementProcessMutation,
} from '../../../redux/vmsApi';
import RequisitionsFilter from '../common/RequisitionsFilter';
const camelCaseToSpaced = (text: string) => text.replace(/([A-Z])/g, ' $1').trim();
const removeHyphens = (text: string) => text.replace(/-/g, ' ');

const ProcurementProcessList: React.FC = () => {
  const [filters, setFilters] = useState({
    title: '',
    approved: undefined,
    development: '',
    reqNumber: '',
    fromDate: '2024-10-01',
    toDate: '2025-12-20',
    currentTask: 'all',
  });
  const [currentOpenRow, setCurrentOpenRow] = useState<string | null>(null);

  const handleToggleDropdown = (rowId: string) => {
    setCurrentOpenRow((prev) => (prev === rowId ? null : rowId));
  };
  const [postProcurementProcess] = usePostCreateProcurementProcessMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSplitClick = async (procurementProcess: any) => {
    setIsSubmitting(true);
    const newProcurementProcess: NewProcurementProcess = {
      currentTask: "PCToUploadQuotations",
      previousTask: "ProjectManagerReviewPurchaseRequisition",
      currentTaskOwner: "procurement-clerk",
      initiatedDate: procurementProcess.initiatedDate,
      lastActionedDate: procurementProcess.lastActionedDate,
      initiatedByUser: procurementProcess.initiatedByUser,
      requisition: {
        id: procurementProcess.requisition.id,
        title: procurementProcess.requisition.title,
        development: {
          id: procurementProcess.requisition.development.id,
          name: procurementProcess.requisition.development.name,
        },
        project: {
          id: procurementProcess.requisition.project.id,
          name: procurementProcess.requisition.project.name,
        },
        totalCost: procurementProcess.requisition.totalCost,
        reqNumber: procurementProcess.requisition.reqNumber,
        approved: procurementProcess.requisition.approved,
        items: procurementProcess.requisition.items.map((item: any) => ({
          description: item.description,
          quantity: item.quantity,
          unit: item.unit,
          procured: item.procured,
          rate: item.rate,
          totalCostPerItem: item.totalCostPerItem,
          discountPerItem: item.discountPerItem,
          VAT: item.VAT,
        })),
        documents: procurementProcess.requisition.documents.map((doc: any) => ({
          name: doc.name,
          url: doc.url,
        })),
      },
      siteManagerReview: {
        user: procurementProcess.siteManagerReview.user,
        comment: procurementProcess.siteManagerReview.comment,
        approved: procurementProcess.siteManagerReview.approved,
        dateReviewed: procurementProcess.siteManagerReview.dateReviewed,
        quotations: Array.isArray(procurementProcess.siteManagerReview.quotations)
          ? procurementProcess.siteManagerReview.quotations
          : [],
      },
      projectManagerReview: {
        user: {
          ...procurementProcess.projectManagerReview.user,
          previousToken:
            procurementProcess.projectManagerReview.user.previousToken || "",
        },
        comment: procurementProcess.projectManagerReview.comment,
        approved: procurementProcess.projectManagerReview.approved,
        dateReviewed: procurementProcess.projectManagerReview.dateReviewed,
        quotations: Array.isArray(procurementProcess.projectManagerReview.quotations)
          ? procurementProcess.projectManagerReview.quotations
          : [],
      },
      status: "New Requisition",
      requisitionDocument: { name: procurementProcess.requisitionDocument?.name, url: procurementProcess.requisitionDocument.url },
      purchaseOrderDocument: { name: "", url: "" },
    };

    const apiArg: PostCreateProcurementProcessApiArg = {
      newProcurementProcess,
    };

    try {
      const response = await postProcurementProcess(apiArg).unwrap();
      console.log(response);
      alert(" procurement process split successfully!");

    } catch (error) {
      console.error("Error creating procurement process:", error);
      alert("Failed to split procurement process. Please try again.");
    } finally {
      setCurrentOpenRow(null);
      setIsSubmitting(false);
    }
  };

  const handleViewProcessDetails = (procurementProcessId: string | undefined) => {
    window.location.href = `https://vms.westprop.com/procurement/procurementProcess/${procurementProcessId}`;
    setCurrentOpenRow(null);
  };

  const [apiParams, setApiParams] = useState({ ...filters, skip: 0, limit: 10 });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data: user } = useGetUsersMeQuery();
  const { data: procurementProcesses, isLoading, isError } = useGetProcurementProcessesQuery(apiParams);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleApplyFilters = () => {
    setApiParams({
      ...filters,
      skip: 0,
      limit: pageSize,
    });
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setApiParams((prevParams) => ({
      ...prevParams,
      skip: newPage * pageSize,
    }));
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setPage(0);
    setApiParams((prevParams) => ({
      ...prevParams,
      limit: newSize,
      skip: 0,
    }));
  };





  const handleNavigate = (task: string, id: string) => {
    window.location.href = `/procurement/${task}/${id}`;
  };

  const isButtonDisabled = (currentTaskOwner: string | undefined) => {
    if (user?.roles?.includes('admin')) {
      return false;
    }
    return !user?.roles?.includes(currentTaskOwner || '');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching Procurement Processes.</div>;
  }

  return (
    <React.Fragment>
      <RequisitionsFilter
        filters={filters}
        onFilterChange={handleFilterChange}
        onApplyFilters={handleApplyFilters}
      />

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Number</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Title</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Requisition Raised By</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Date Raised</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Current Task</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Current Task Owner</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Previous Task</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Development</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementProcesses && procurementProcesses.length > 0 ? (
              procurementProcesses.map((procurementProcess: ProcurementProcessWithRelations, index: number) => (
                <TableRow key={procurementProcess.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                  <TableCell component="th" scope="row">
                    {procurementProcess.requisition?.reqNumber || 'N/A'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {procurementProcess.requisition?.title || 'N/A'}
                  </TableCell>

                  <TableCell align="right">
                    {procurementProcess.initiatedByUser?.name || 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.initiatedDate ? new Date(procurementProcess.initiatedDate).toLocaleDateString() : 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                     
                      {index < 3 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px',
                            backgroundColor: 'red',
                            color: 'white',
                            padding: '2px 6px',
                            borderRadius: '8px',
                            fontSize: '0.7rem',
                            fontWeight: 'bold',
                            zIndex: 1,
                          }}
                        >
                          NEW
                        </Box>
                      )}
                      
                      <Button
                        onClick={() => handleNavigate(`${procurementProcess.currentTask}`, `${procurementProcess.id}`)}
                        disabled={isButtonDisabled(procurementProcess.currentTaskOwner)}
                        sx={{
                          backgroundColor: '#F7C914',
                          color: 'white',
                          borderRadius: '8px',
                          position: 'relative',
                          '&:hover': {
                            backgroundColor: '#1976D2',
                          },
                        }}
                        startIcon={
                          isButtonDisabled(procurementProcess.currentTaskOwner) ? null : (
                            <Box component="span" className="material-icons">
                              arrow_forward
                            </Box>
                          )
                        }
                      >
                        {camelCaseToSpaced(procurementProcess.currentTask || 'N/A')}
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.currentTaskOwner ? removeHyphens(procurementProcess.currentTaskOwner) : 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    {camelCaseToSpaced(procurementProcess.previousTask || 'N/A')}
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.requisition?.development?.name || 'N/A'}, {procurementProcess.requisition?.project?.name || 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ position: "relative", display: "inline-block" }}>
                      <Button
                        sx={{
                          backgroundColor: "primary.main",
                          color: "white",
                          "&:hover": { backgroundColor: "primary.dark" },
                        }}
                        onClick={() => handleToggleDropdown(procurementProcess.id || "")}
                      >
                        Actions
                      </Button>
                      {currentOpenRow === procurementProcess.id && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            zIndex: 1,
                            mt: 1,
                            minWidth: "150px",
                            backgroundColor: "white",
                            boxShadow: 3,
                            borderRadius: 1,
                            overflow: "hidden",
                          }}
                        >

                          <Button
                            sx={{
                              display: "block",
                              width: "100%",
                              textAlign: "left",
                              px: 2,
                              py: 1,
                              "&:hover": { backgroundColor: "grey.100" },
                            }}
                            onClick={() => handleViewProcessDetails(procurementProcess.id)}
                          >
                            View
                          </Button>
                          <Button
                            sx={{
                              display: "block",
                              width: "100%",
                              textAlign: "left",
                              px: 2,
                              py: 1,
                              "&:hover": { backgroundColor: "grey.100" },
                            }}
                            onClick={() => handleSplitClick(procurementProcess)}
                            disabled={isSubmitting}
                          >
                            Split
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No matching Procurement Processes found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>


      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
          disabled={page === 0}
          variant="contained"
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>

        <Typography variant="body1">Page {page + 1}</Typography>

        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={procurementProcesses?.length === undefined || procurementProcesses.length < pageSize}
          variant="contained"
          sx={{ marginLeft: 2 }}
        >
          Next
        </Button>
      </Box>


      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ backgroundColor: '#f0f4f7' }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </React.Fragment>
  );
};

export default ProcurementProcessList;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgreementsDashboard from "./AgreementsDashboard";
import ClientAgreements from "./ClientAgreements";
import EmployeeContracts from "./EmployeeContracts";

const AgreementsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/agreements" component={AgreementsDashboard} />
      <Route exact path="/agreements/client-agreements" component={ClientAgreements} />
      <Route exact path="/agreements/employee-contracts" component={EmployeeContracts} />

    </Switch>
  );
}

export default AgreementsRoutes;

import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Grid, Card, CardContent, Typography, Box, Tooltip, styled} from '@mui/material';
import { Link } from 'react-router-dom';
import {Assignment, LocalShipping, RateReview, Description, Home, Badge, Visibility , PrecisionManufacturing} from '@mui/icons-material';
import { useAppSelector } from "../../redux/hooks";
import LoggedOutView from "../common/LoggedOutView";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledCard = styled(Card)(() => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  transition: '0.3s',
  borderRadius: '15px',
  padding: '16px',
  '&:hover': {
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
    transform: 'scale(1.01)',
  },
}));

const DisabledCard = styled(Card)(() => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#b0b0b0',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
  padding: '16px',
}));

const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#fff',
  fontSize: '1.25rem',
}));

const StyledIcon = styled('div')({
  fontSize: '60px',
  marginBottom: '10px',
  color: '#fff',
});

const Dashboard: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)
  const currentUser = useAppSelector(state => state.auth.user)

  const userAccess = {
    invite: !!(currentUser && currentUser.roles?.includes('vms')),
    procurement: !!(currentUser && currentUser.roles?.includes('procurement')),
    reviews: true,
    agreements: !!(currentUser && currentUser.roles?.includes('agreement')),
    payroll: !!(currentUser && currentUser.roles?.includes('payroll')),
    propertyRegister: !!(currentUser && currentUser.roles?.includes('propertyRegister')),
    accessControl: true,
    cSuite: !!(currentUser && currentUser.roles?.includes('cSuite')),
    siteInspector: !!(currentUser && currentUser.roles?.includes('siteInspector')),
    wdc: !!(currentUser && currentUser.roles?.includes('admin')),
  };

  const options = [
    { id: 2, title: 'Performance System', link: `/reviews`, icon: <RateReview sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.reviews },
    { id: 3, title: 'Procurement', link: `/procurement`, icon: <LocalShipping sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.procurement },
    { id: 4, title: 'Invite Management', link: `/invite`, icon: <Assignment sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.invite },
    { id: 5, title: 'Agreements', link: `/agreements`, icon: <Description sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.agreements },
    { id: 7, title: 'Payroll', link: `/payroll`, icon: <AttachMoneyIcon sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.payroll },
    { id: 6, title: 'Property Register', link: `/property-register`, icon: <Home sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.propertyRegister },
    { id: 7, title: 'Site Access', link: `/access-control`, icon: <Badge sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.accessControl },
    { id: 8, title: 'Site Inspections', link: `/site-inspections`, icon: <Visibility sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.siteInspector },
    { id: 9, title: 'Plant Reports', link: `/plant-reports`, icon: <PrecisionManufacturing sx={{ fontSize: 60, mb: 1, color: '#fff' }} />, access: userAccess.wdc },

  ];

  if (!user) {
    return <LoggedOutView />
  }

  return (
    <PageWithHeader>
      <Box m={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456', fontSize: '2.5rem', textAlign: 'center' }}>
          WestProp Operations Hub
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {options.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.id}>
              {option.access ? (
                <Link to={option.link} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                  <StyledCard>
                    <CardContent style={{ textAlign: 'center' }}>
                      {option.icon}
                      <StyledTypography variant="h6">{option.title}</StyledTypography>
                    </CardContent>
                  </StyledCard>
                </Link>
              ) : (
                <Tooltip title="You do not have access to this function" arrow>
                  <Box>
                    <DisabledCard>
                      <CardContent style={{ textAlign: 'center' }}>
                        {option.icon}
                        <StyledTypography>{option.title}</StyledTypography>
                      </CardContent>
                    </DisabledCard>
                  </Box>
                </Tooltip>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageWithHeader>
  );
}

export default Dashboard;

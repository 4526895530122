import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ViewRequisition from "./ViewRequisition";
import DevelopmentsPage from "./pages/DevelopmentsPage";
import UnitsPage from "./pages/UnitsPage";


import CreateRequisitionReviewPage from './pages/CreateRequisitionReviewPage';
import ProcurementProcess from './dashboards/ProcurementProcessDashboard';
import CreateRequisitionPage from './pages/CreateRequisitionPage';
import BillOfMaterialsPage from './pages/BillOfMaterialsPage';
import SuppliersPage from './pages/SuppliersPage';
import ProcurementProcessDetailsPage from './pages/ProcurementProcessDetailsPage';
import RequisitionsPage from './pages/RequisitionsPage';
import PurchaseOrdersPage from './pages/PurchaseOrdersPage';

const ProcurementRoutes = () => {
  return (
    <Switch>
      <Route exact path="/procurement" component={ProcurementProcess} />
      <Route exact path="/procurement/create-requisition" component={CreateRequisitionPage} />
      <Route exact path="/procurement/RaisePurchaseRequisition/:uuid" component={CreateRequisitionPage} />
      <Route exact path="/procurement/requisitions" component={RequisitionsPage} />
      <Route exact path="/procurement/purchase-orders" component={PurchaseOrdersPage} />
      <Route exact path="/procurement/CorrectPurchaseRequisition/:uuid" component={CreateRequisitionPage} />
      <Route exact path="/procurement/create-procurement-request" component={CreateRequisitionPage} />
      <Route exact path="/procurement/SiteManagerReviewPurchaseRequisition/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/ProjectManagerReviewPurchaseRequisition/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/CPOPicksSupplier/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PCToUploadQuotations/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PMToReviewProcurementApplication/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/ProChairReviewsSupplierChoice/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PMToIssuePurchaseOrder/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/CFOAllocateFunds/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/procurementProcess/:uuid" component={ProcurementProcessDetailsPage} />
      <Route exact path="/procurement/developments" component={DevelopmentsPage} />
      <Route exact path="/procurement/units" component={UnitsPage} />
      <Route exact path="/procurement/bom" component={BillOfMaterialsPage} />
      <Route exact path="/procurement/create-requisition-review" component={CreateRequisitionReviewPage} />
      <Route exact path="/procurement/procurement-process-dashboard" component={ProcurementProcess} />
      <Route exact path="/procurement/stakeholders" component={SuppliersPage} />
    

    </Switch>
  );
}

export default ProcurementRoutes;

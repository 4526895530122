import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';
import { Home, Badge } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(() => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '15px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  color: '#fff',
});

const AgreementsDashboard: React.FC = () => {
  const { url } = useRouteMatch();

  const options = [
    { id: 1, title: 'Client Agreements', link: `${url}/client-agreements`, icon: <Home sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 2, title: 'Employee Contracts', link: `${url}/employee-contracts`, icon: <Badge sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
  ];

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Typography variant='h1' gutterBottom sx={{ fontWeight: 'bold', color: '#123456' }}>
          Agreement Generation System
        </Typography>

        <Grid container spacing={3} pt={3}>
          {options.map((option) => (
            <Grid item xs={6} key={option.id}>
              <Link to={option.link} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                <StyledCard>
                  <CardContent style={{ textAlign: 'center' }}>
                    {option.icon}
                    <StyledTypography variant="h6">{option.title}</StyledTypography>
                  </CardContent>
                </StyledCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </PageWithHeader>
  );
}

export default AgreementsDashboard;
